import { HttpClient } from "@angular/common/http";
import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { UsermanagementService } from "../usermanagement.service";
import { LoginService } from "../../login.service";
import { FileuploadComponent } from "src/app/fileupload/fileupload.component";
import Swal from "sweetalert2";
import { Router, ActivatedRoute } from "@angular/router";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { InboundFeedbackService } from "../../inbound-feedback/inbound-feedback.service";
import { InboundRouteService } from "../../inbound-route/inbound-route.service";
import { ConstantPool } from "@angular/compiler";


@Component({
  selector: "[app-usermanagement-detail]",
  templateUrl: "./usermanagement-detail.component.html",
  styleUrls: ["./usermanagement-detail.component.css"],
})
export class UserProfileDetailComponent implements OnInit {
  public userData: any;
  public allProducts: any = [];

  keyword = "name";
  isAdmin: string = "false";
  didBox: string = "false";
  UserProfileId: number = 0;
  id: number = 0;
  did: string;
  dids: string = "";
  cpp: string;
  pulse: string;
  mobile: string;
  address: string = "";
  ChatId: any;
  UserProfileData: any;
  UserData: any;
  username: string;
  customername: string;
  description: string;
  email: string = "";
  user_id: number;
  password: string | null = null;
  channels: string;
  GROUP: any;
  grouplist: any;
  groupInitial: any;
  dialerPad: any;
  report: any;
  group: any;
  existedUser: any;
  DIDNO: number = 0;
  callerDIDNumber: string = "";
  didList: any;
  destination: string = "";
  dialerPadDid: any;
  DialerDID: string = "";
  DialerDIDInitial: any;
  val: string;
  individualDialerDID: any;
  individualDialerDIDInitial: any;
  valTwo: string;
  individual: any;
  individualDidBox: string;
  userType: string;
  product: string;
  smsType: string = "greenads"
  smsProviderJson: string = ""
  showSmsProviderJson: boolean = false;

  // Client Details //
  activationDate: string;
  fromRenewalDate: string;
  renewalDate: string;
  products: any = [];
  AddedProducts: any = [];
  status: string;
  plan: string;
  remark: string;
  addProduct: boolean = false;
  showClientInputs: boolean = false;
  showToken: boolean = false;
  token: string = "";
  user = new User();
  autocallEnabled: boolean = false;
  enableMobileAutoCall: boolean = false;
  enableDataPush: boolean = false;
  autoCallURL: string = "";
  autoCallJson: string = "";
  autoCallClientId: string = "";
  form: FormGroup = new FormGroup({});

  constructor(
    private loginservice: LoginService,
    private usermanagementservice: UsermanagementService,
    private route: Router,
    private activeroute: ActivatedRoute,
    private inboundFeedbackService: InboundFeedbackService,
    private inboundservice: InboundRouteService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.form = fb.group({
      mobileNumber: [
        "",
        [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{12}$")],
      ],
      EmailPattern: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"),
        ],
      ],
      cpp: [
        "",
        Validators.required,
      ]

    });
  }

  get f() {
    return this.form.controls;
  }
  submit() {
    console.log(this.form.value);
  }

  ngOnInit() {
    this.isAdmin = localStorage.getItem("isAdmin");
    this.userData = this.loginservice.getUserdata(false);
    this.id = +this.activeroute.snapshot.paramMap.get("id");

    console.log("User Details      :  ", this.userData);
    this.userType = this.userData.usergroup;

    if (this.userData.usergroup == "superadmin") {
      this.showClientInputs = true;
    }

    if (this.id != 0) {
      this.didBox = "true";
      this.individualDidBox = "true";
    }
    if (this.isAdmin == "false") {
      this.UserProfileId = this.userData.id;
    } else {
      let userID = JSON.parse(localStorage.getItem("customer"));
      if (userID != null) {
        this.UserProfileId = userID.id;
      }
    }
    this.id = +this.activeroute.snapshot.paramMap.get("id");

    this.usermanagementservice.getProducts().subscribe((data: any) => {
      this.allProducts = data;
    });

    this.usermanagementservice
      .getUserProfile(this.id)
      .subscribe((data: any) => {
        this.address = data.user_profile.address;
        this.cpp = data.user_profile.cpp;
        this.pulse = data.user_profile.pulse;
        this.mobile = data.user_profile.mobile;
        this.username = data.user_profile.username;
        this.customername = data.user_profile.name;
        this.description = data.user_profile.description;
        this.email = data.user_profile.email;
        this.user_id = data.user_profile.user;
        this.dids = data.user_profile.did_numbers;
        this.channels = data.user_profile.no_of_channels;
        this.dialerPad = data.user_profile.dialerPadPermission;
        this.report = data.user_profile.reportPermission;
        this.individual = data.user_profile.individualDidPermission;
        this.group = data.user_profile.group;
        this.DialerDIDInitial = data.user_profile.dialerPadDid;
        this.token = data.user_profile.token;
        this.individualDialerDIDInitial = data.user_profile.ownDialerDid;
        this.smsType = data.user_profile.smsType
        this.autocallEnabled = data.user_profile.autocall;
        this.ChatId = data.user_profile.chat_id;
        this.password = data.user_profile.password;

        if (data.user_profile.smsCustomData) {
          try {
            this.smsProviderJson = JSON.stringify(data.user_profile.smsCustomData, null, 2);
            this.showSmsProviderJson = true;
          } catch (e) {
            console.error("Failed to parse smsCustomData:", e);
            this.smsProviderJson = null;
            this.showSmsProviderJson = false;
          }
        } else {
          this.smsProviderJson = null;
          this.showSmsProviderJson = false;
        }

        if (Object.keys(data.autocall_client).length > 0) {
          this.enableMobileAutoCall = data.autocall_client.mobile_autocall
          this.enableDataPush = data.autocall_client.send_data_url
          this.autoCallURL = data.autocall_client.url_name
          this.autoCallJson = data.autocall_client.AutocallFormat !== null ? JSON.stringify(data.autocall_client.AutocallFormat) : null
          this.autoCallClientId = data.autocall_client.id
        }

        // this.remark = data.client_details[0]?.remark;

        data.client_details.forEach((clientDetail: any) => {
          this.products.push(clientDetail);
          this.AddedProducts.push(clientDetail);
        });
      });

  }

  handleInputChange(selectedProductName: string) {
    const isProductInList = this.products.some(
      (product) => product.product === selectedProductName
    );

    // If the product is not in the list, add it
    if (!isProductInList) {
      // Create a new product object with initial data
      this.addProduct = true;
      const newProduct = {
        product: selectedProductName,
        activationDate: "",
        fromRenewalDate: "",
        renewalDate: "",
        plan: "",
        status: "",
        remark: "",
      };
      this.products.push(newProduct);
      console.log("PRODUCTS: ", this.products);
    } else {
      console.log("Product already exists in the list.");
    }
  }

  onSelectActivationDate(event: Event, product: string) {
    const activation_date = (event.target as HTMLInputElement).value;
    const productToUpdate = this.products.find(
      (item) => item.product === product
    );

    if (productToUpdate) {
      productToUpdate.activationDate = activation_date;
    }
  }

  onSelectRenewalDate(event: Event, product: string) {
    const renewal_date = (event.target as HTMLInputElement).value;
    const productToUpdate = this.products.find(
      (item) => item.product === product
    );

    if (productToUpdate) {
      productToUpdate.renewalDate = renewal_date;
    }
  }

  onSelectFromRenewalDate(event: Event, product: string) {
    const from_renewal_date = (event.target as HTMLInputElement).value;
    const productToUpdate = this.products.find(
      (item) => item.product === product
    );

    if (productToUpdate) {
      productToUpdate.fromRenewalDate = from_renewal_date;
    }
  }

  handlePlanChange(plan, product: string) {
    const productToUpdate = this.products.find(
      (item) => item.product === product
    );
    if (productToUpdate) {
      productToUpdate.plan = plan;
    }
  }

  handleStatusChange(status, product: string) {
    const productToUpdate = this.products.find(
      (item) => item.product === product
    );
    if (productToUpdate) {
      productToUpdate.status = status;
    }
  }

  EditProduct(product: string) {
    const productToUpdate = this.products.find(
      (item) => item.product === product
    );

    if (productToUpdate) {
      this.UserData = {
        username: this.username,
        first_name: this.customername,
        mobile: this.mobile,
        product: productToUpdate,
      };
      this.usermanagementservice
        .UpdateProduct(this.user_id, this.UserData)
        .subscribe((res: any) => {
          Swal.fire("Updated", "User Product Successfully Updated", "success");
        });
    }
  }

  closeSelectedProduct(product) {

    const indexToRemove = this.products.findIndex(
      (item) => item.product === product
    );
    if (this.id === 0) {
      if (indexToRemove !== -1) {
        this.products.splice(indexToRemove, 1);
      }
    } else {
      this.UserData = {
        username: this.username,
        product: product,
      };
      this.usermanagementservice
        .DeleteProduct(this.user_id, this.UserData)
        .subscribe((res: any) => {
          Swal.fire("Deleted", "Deleted", "success");
          if (res.status == 1) {
            if (indexToRemove !== -1) {
              this.products.splice(indexToRemove, 1);
            }
          } else {
            if (indexToRemove !== -1) {
              this.products.splice(indexToRemove, 1);
            }
          }
        });
    }
  }

  AddProduct() {
    this.usermanagementservice
      .AddProduct(this.product)
      .subscribe((res: any) => {
        if (res.message == 1) {
          Swal.fire("Error", "This product already exists");
        } else if (res.message == 2) {
          Swal.fire("Success", "Product added successfully");
        } else {
          Swal.fire("Error", res.message);
        }
      });
  }

  onUserprofileBtn() {
    {
      this.UserProfileData = {
        address: this.address,
        cpp: this.cpp,
        pulse: this.pulse,
        mobile: this.mobile,
        username: this.username,
        name: this.customername,
        email: this.email,
        description: this.description,
        dids: this.dids,
        password: this.password,
        channels: this.channels,
        usergroup: this.GROUP,
        company: this.UserProfileId,
        dialerPad: this.dialerPad,
        report: this.report,
        dialerPadDid: this.DialerDID,
        ownDialerDid: this.individualDialerDID,
        individualDidPermission: this.individual,
        ChatId: this.ChatId,
        products: this.products,
        remark: this.remark,
        userType: this.userType,
        autocall: this.autocallEnabled,
        autoCallURL: this.autoCallURL,
        enableMobileAutoCall: this.enableMobileAutoCall,
        enableDataPush: this.enableDataPush,
        autoCallJSON: this.autoCallJson,
        smsType: this.smsType,
        smsProviderJson: this.smsProviderJson
      };

      if (this.isAdmin == "true") {
        if (this.form.get('cpp').invalid) {
          Swal.fire("Oops..!", "Please Fill  All The Mandatory Fields", "warning");
        }
        else if (
          this.UserProfileData.address == "" ||
          this.UserProfileData.email == "" ||
          this.UserProfileData.mobile == "" ||
          this.UserProfileData.name == "" ||
          this.UserProfileData.password == "" ||
          this.UserProfileData.description == "" ||
          this.UserProfileData.cpp == ""
        ) {
          Swal.fire(
            "Oops..!",
            "Please Fill All The Mandatory Fields",
            "warning"
          );
        } else {
          this.inboundFeedbackService
            .userExistTestUsrMngmnt(this.username)
            .subscribe((res: any) => {
              this.existedUser = res;

              if (this.existedUser["status"] == "0") {
                this.usermanagementservice
                  .addUserProfile(this.UserProfileData)
                  .subscribe((res: any) => {
                    Swal.fire(
                      "Created",
                      "User Profile Successfully Created",
                      "success"
                    );

                    this.route.navigateByUrl("/users/user");
                  });
              } else {
                Swal.fire(
                  "Oops..!",
                  "Please change the user Name, Already Exist",
                  "warning"
                );
              }
            });
        }
      } else {
        this.inboundFeedbackService
          .userExistTestUsrMngmnt(this.username)
          .subscribe((res: any) => {
            this.existedUser = res;
            if (this.existedUser["status"] == 0) {
              this.usermanagementservice
                .addSubUserProfile(this.UserProfileData)
                .subscribe((res: any) => {
                  Swal.fire(
                    "Created",
                    "User Profile Successfully Created",
                    "success"
                  );
                  this.router.navigate(["/users/user/"]);
                });
            } else {
              if (
                this.UserProfileData.address == "" ||
                this.UserProfileData.email == "" ||
                this.UserProfileData.mobile == "" ||
                this.UserProfileData.name == "" ||
                this.UserProfileData.password == "" ||
                this.UserProfileData.description == ""
              ) {
                Swal.fire(
                  "Oops..!",
                  "Please Fill All The Mandatory Fields",
                  "warning"
                );
              } else {
                Swal.fire(
                  "Oops..!",
                  "Please change the user Name, Already Exist",
                  "warning"
                );
              }
            }
          });
      }
    }
  }

  adddids = function () {
    this.dids += this.did + ",";
    this.did = "";
  };
  // DID AutoCompleted Functions

  selectgroup(item) {
    this.GROUP = item.id;
  }

  onChangeSearchgroup(val: string) {
    this.usermanagementservice
      .searchgroupList(val, this.UserProfileId)
      .subscribe((data: any) => {
        this.grouplist = data;
      });
  }

  onFocusedgroup(e) {
    this.usermanagementservice
      .getgroupList(this.UserProfileId)
      .subscribe((data: any) => {
        this.grouplist = data;
      });
  }

  onCleargroup() {
    this.GROUP = 0;
  }

  onUpdateUserprofileBtn() {
    if (this.DialerDIDInitial == null) {
      this.val = " ";
    }
    if (this.DialerDIDInitial != null) {
      this.val = this.DialerDIDInitial.name;
    }
    if (this.individualDialerDIDInitial == null) {
      this.valTwo = " ";
    }
    if (this.individualDialerDIDInitial != null) {
      this.valTwo = this.individualDialerDIDInitial.name;
    }

    this.UserData = {
      username: this.username,
      first_name: this.customername,
      email: this.email,
    };

    this.usermanagementservice
      .updateUserData(this.user_id, this.UserData)
      .subscribe((res: any) => {


        this.UserProfileData = {
          address: this.address,
          cpp: this.cpp,
          pulse: this.pulse,
          mobile: this.mobile,
          description: this.description,
          user: res.id,
          did_numbers: this.dids,
          no_of_channels: this.channels,
          dialerPadPermission: this.dialerPad,
          reportPermission: this.report,
          dialerPadDid: this.val,
          ownDialerDid: this.valTwo,
          individualDidPermission: this.individual,
          chat_id: this.ChatId,
          products: this.products,
          userType: this.userType,
          password: this.password,
          smsType: this.smsType,
          autocall: this.autocallEnabled,
          autoCallURL: this.autoCallURL,
          enableMobileAutoCall: this.enableMobileAutoCall,
          enableDataPush: this.enableDataPush,
          autoCallJSON: this.autoCallJson,
          autoCallClientId: this.autoCallClientId,
          smsCustomData: this.smsProviderJson
        };

        if (
          this.UserProfileData.address == "" ||
          this.UserProfileData.email == "" ||
          this.UserProfileData.mobile == "" ||
          this.UserProfileData.name == "" ||
          this.UserProfileData.description == ""
        ) {
          Swal.fire(
            "Oops..!",
            "Please Fill All The Mandatory Fields",
            "warning"
          );
        } else {

          this.usermanagementservice.updateUserProfileData(this.id, this.UserProfileData)
            .subscribe(
              (res: any) => {
                Swal.fire(
                  "Updated",
                  "User Profile Successfully Updated",
                  "success"
                );
                this.router.navigate(["/users/user/"]);
              },
              (error: any) => {
                // Check if the error is related to password validation
                if (error.error && error.error.error) {
                  Swal.fire(
                    "Password Validation Error",
                    error.error.error,
                    "error"
                  );
                } else {
                  Swal.fire(
                    "Error",
                    "An error occurred while updating the profile",
                    "error"
                  );
                }
              }
            );
        }
      });
  }


  onClick(event: Event) {
    if (this.isAdmin == "true") {
      this.didBox = "true";
    } else {
      this.individualDidBox = "true";
    }
  }

  onClicks(event: Event) {
    this.didBox = "true";
  }

  selectDID(item) {
    this.DIDNO = item.id;
  }

  selectcallerDID(item) {
    this.DialerDID = item.id;
  }

  onChangeSearchDID(val: string, channel: string) {
    this.inboundservice
      .searchInboundDIDList(val, this.UserProfileId, this.destination)
      .subscribe((data: any) => {
        this.didList = data;
      });
  }

  onFocusedDID(e, channel: string, type) {

    if (channel == "samechannel") {
      this.inboundservice
        .getInboundDIDList(this.UserProfileId, this.destination, type)
        .subscribe((data: any) => {
          this.didList = data;
        });
    } else if (channel == "dialerDid") {
      this.inboundservice
        .getInboundDIDList(this.UserProfileId, this.username, type)
        .subscribe((data: any) => {
          this.didList = data;
        });
    } else {
      this.inboundservice
        .getInboundDIDList(this.UserProfileId, "notQueue", type)
        .subscribe((data: any) => {
          this.didList = data;
        });
    }
  }

  toggleTokenVisibility() {
    this.showToken === true ? this.showToken = false : this.showToken = true;
  }

  onCopyToken() {
    window.navigator['clipboard'].writeText(this.token)
    Swal.fire("Success", "Token copied to clipboard.", "info")
  }

  generateToken() {
    this.usermanagementservice.generateToken(this.user_id).subscribe((res: any) => {
      if (res.status === "1") {
        this.token = res.token
        Swal.fire("Success", "Token generated successfully.", "success")
      } else {
        Swal.fire("Something went wrong!", "res.error", "error")
      }
    })

  }

  enableAutoCallClient() {
    this.usermanagementservice.enableAutoCallClient(this.id).subscribe((res: any) => {
      if (res.status === "1") {
        Swal.fire("Success", "Autocall client settings are enabled.", "success")
        this.autoCallClientId = res.autocall_client.id
      }
      else {
        Swal.fire("Something went wrong !", res.error, "error")
      }
    })
  }

  getSmsProviderData(smsType:string){
    this.usermanagementservice.getSmsProviderData(smsType).subscribe(
      (res:any) => {
        this.smsProviderJson = res.default_data
      }
    )
  }

  onSMSTypeChange(event: any) {
    this.smsType = event.target.value;
    
    if (this.smsType === 'greenads' || this.smsType === 'SMSGW') {
      this.smsProviderJson = ''
      this.showSmsProviderJson = false;
    } else {
      this.getSmsProviderData(this.smsType)
      this.showSmsProviderJson = true;
    }
  }
}

export class User {
  email?: string;
  mobile?: string;
}
