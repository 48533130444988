import { Component, OnInit, OnChanges, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { WhatsappService } from '../whatsapp-services';

@Component({
  selector: 'app-whatsapp-user',
  templateUrl: './whatsapp-user.component.html',
  styleUrls: ['./whatsapp-user.component.css']
})
export class WhatsappUserComponent implements OnInit, OnChanges {

  userList: any = new Array();
  keyword: string = "username";

  @Input() userGroup: string;
  @Input() userId: string;
  @Input() userValueInitial: string;
  @Input() readOnly: boolean;
  @Input() validationError: boolean;
  @Input() clearTrigger: boolean;
  @Output() userEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor(private whatsappService: WhatsappService) { }

  ngOnInit() {
    this.loadUserList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.clearTrigger && changes.clearTrigger.currentValue) {
      this.clearSelection();
    }
  }

  loadUserList() {
    const qParams = [
      { key: "scope", value: this.userGroup },
      { key: "userprofile_id", value: this.userId }
    ];
    this.whatsappService.getWhatsappUsersList(qParams).subscribe(
      (res: any) => {
        if (res.status === "1") {
          this.userList = res.data;
          if (this.userGroup != "superadmin") {
            const user = this.userList.find(item => item.whatsapp_user = this.userId);
            this.userValueInitial = user.username;
            this.userEmitter.emit(user.id)
          }
        }
      }
    );
  }

  clearSelection() {
    this.userValueInitial = null;
  }

  selectUser(item) {
    this.userEmitter.emit(item.id);
    this.validationError = false;
  }

  onFocusedUser() {
    this.loadUserList();
  }

  onChangeSearchUser(val: string) {
    this.userList = this.userList.filter(user=> user.username.startsWith(val))
  }

  onClearUser() {
    this.userEmitter.emit(0);
  }
}
