import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LoginService } from 'src/app/login.service';
import { ReportSettingsService } from '../report-settings.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-parent-tag-icon',
  templateUrl: './parent-tag-icon.component.html',
  styleUrls: ['./parent-tag-icon.component.css']
})
export class ParentTagIconComponent implements OnInit {

  @Output() mainTagDeleted = new EventEmitter<boolean>();

  showTagIcon: boolean = false
  tagArray:any = new Array()
  tag_name: any
  userData: any

  constructor(
    private loginservice: LoginService,
    private reportsettingservice: ReportSettingsService
  ) { }

  ngOnInit() {
    this.userData = this.loginservice.getUserdata(true);
  }

  toggleTagIcon(){
    this.showTagIcon = !this.showTagIcon

    if(this.showTagIcon){
      this.getReportTags()
    }
  }

  getReportTags(){
    this.reportsettingservice.getReportTagMain(this.userData.id).subscribe((res:any) => {
      this.tagArray = res.report_tags
    })
  }

  createTag(){
    const body = {
      'user_id': this.userData.id,
      'tag': this.tag_name
    }
    this.reportsettingservice.addReportTagMain(this.userData.id, body).subscribe((res:any) => {
      this.tag_name = ''
      this.tagArray = res.report_tags.split(',').map(tag => tag.trim())
    })
  }

  deleteTag(tag: string){
    Swal.fire({
      title: 'Are you sure ?',
      text: 'Deleting this tag will remove it from all call records',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'No, Cancel it!'
    }).then((result) => {
      if (result.value) {
        const body = {
          'tag': tag
        }
        this.reportsettingservice.deleteReportTagMain(this.userData.id, body).subscribe((res:any) => {
          this.getReportTags()
          this.mainTagDeleted.emit(true)
        })
      }
    })
  }
}
