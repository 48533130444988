import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SharedService } from 'src/global';

@Injectable({
  providedIn: 'root'
})
export class ReportSettingsService {

  constructor(
    private http: HttpClient,
    public sharedService:SharedService
  ) { }

  API_URL = this.sharedService.getGlobalVar()

  getReportTagMain(user_id: any){
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'report_tag_main/'+user_id+'/', {headers: headers})
  }

  addReportTagMain(user_id: any, body: any){
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.API_URL+'report_tag_main/'+user_id+'/', body, {headers: headers})
  } 

  deleteReportTagMain(user_id: any, body: any){
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.request('delete',this.API_URL + 'report_tag_main/' + user_id + '/', {headers: headers, body: body})
  }

  getReportTagChild(call_record_id: any){
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(this.API_URL+'report_tag_child/'+call_record_id+'/', {headers: headers})
  }

  addReportTagChild(call_record_id: any, body: any){
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(this.API_URL+'report_tag_child/'+call_record_id+'/', body, {headers: headers})
  }

  deleteReportTagChild(call_record_id: any, body: any){
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.request('delete',this.API_URL + 'report_tag_child/' + call_record_id + '/', {headers: headers, body: body})
  }
}
