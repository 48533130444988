import { Component, OnInit} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ZohoIntegrationService } from './zoho-integration.service';
import { Router, NavigationStart } from '@angular/router';
import { LoginService } from 'src/app/login.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-zoho-integration',
  templateUrl: './zoho-integration.component.html',
  styleUrls: ['./zoho-integration.component.css']
})
export class ZohoIntegrationComponent implements OnInit {

  zohoForm: FormGroup;
  userData: any;
  crmConfigValid: boolean = true;
  integrationEnabled: boolean = false;
  crmConfigErrors: string[] = [];
  sendDataToExternalAPIID: string = "";
  userName: string = "";
  phoneNumber: string = "";
  zohoCRMArray: any[] = [];
  showModal: boolean = false;
  integrationLink: string = "";
  toolTip: boolean = false;
  onDelete: boolean = false;
  onDeleteCRMID: string = "";
  onCRMActivationStep: boolean = false;
  onConfigureCRMStep: boolean = false;
  crmConfigForm: FormGroup;
  crmConfigUserId: string = "";
  vsipEnabled: boolean = false;
  adminUser: any;

  constructor(
    private fb: FormBuilder,
    private zohoIntegrationService: ZohoIntegrationService,
    private loginService: LoginService,
    private router: Router
  ) {

    this.zohoForm = this.fb.group({
      agentName: ["", Validators.required],
      agentNumber: ["", [Validators.required, Validators.pattern(/^\d{10}$/)]],
      zohoUserId: [""],
      crmServer: [""],
      vsipEnabled: [false],
      vsipId: ["", []]
    });

    this.crmConfigForm = this.fb.group({
      client_id: ["", Validators.required],
      client_secret: ["", Validators.required]
    });
  }

  ngOnInit() {

    this.userData = this.loginService.getUserdata(true);
    this.checkCRMConfig(this.userData.id);   

  }



  checkCRMConfig(user_id) {
    this.zohoIntegrationService.getCRMConfig(user_id).subscribe((res: any) => {
      if (res["status"] == "1") {
        const data = res["data"]
        this.sendDataToExternalAPIID = data["id"]
        this.userName = data["username"]
        this.phoneNumber = data["phone_number"].slice(-10)
        if (data["format"] != "zoho") {
          this.crmConfigValid = false;
          this.crmConfigErrors.push("Format is not configured")
        }
        if (data["autocall"] !== "true" && data["autocall"] !== "false") {
          this.crmConfigValid = false;
          this.crmConfigErrors.push("Autocall is not configured");
        }

        if (data["autocall_did"] == "") {
          this.crmConfigValid = false;
          this.crmConfigErrors.push("Autocall DID is not configured")
        }

        if (data["autocall_channel_id"] == "") {
          this.crmConfigValid = false;
          this.crmConfigErrors.push("Autocall channel id is not configured")
        }

        this.initializeCRMTable();
        
      }
      else if (res["status"] == "0" && res["error"] == "Config not found.") {
        this.crmConfigValid = false;
        this.crmConfigErrors.push("CRM configuration is not found.")
      }

    })

    
  }

  initializeCRMTable() {
    if (this.crmConfigValid) {
      const qParams = [{ key: "user_id", value: this.sendDataToExternalAPIID }]
      this.zohoIntegrationService.getZohoAgentList(qParams).subscribe((res: any) => {
        if (res["status"] == "1") {
          this.zohoCRMArray = res["data"].filter(item => item.is_admin == false)
          this.adminUser = res.data.find(
            item =>
              item.agent_number == this.phoneNumber &&
              item.is_admin == true
          )
          if (this.adminUser) {
            if (this.adminUser.phoneBridgeEnabled) {
              this.integrationEnabled = true;
            } else {
              this.integrationEnabled = false;
            }
          }

        }
      });
          
    }
  }



  onSubmit() {
    this.markFormGroupAsTouched(this.zohoForm);

    if (this.zohoForm.valid) {
      const data = {
        SendDataToExternalAPI: this.sendDataToExternalAPIID,
        agent_name: this.zohoForm.value.agentName,
        agent_number: this.zohoForm.value.agentNumber,
        zoho_user_id: this.zohoForm.value.zohoUserId,
        // crm_server: this.zohoForm.value.crmServer,
        vsipEnabled: this.zohoForm.value.vsipEnabled,
        vsip_number: this.zohoForm.value.vsipId
      }
      this.zohoIntegrationService.addZohoAgent(data).subscribe((res: any) => {

        if (res["status"] == "1") {
          this.zohoForm.reset();
          this.zohoForm.get("vsipEnabled").patchValue(false)
          let zohoCRMID = res["data"].id
          const qParams = [{ key: "user_id", value: this.sendDataToExternalAPIID }]
          this.zohoIntegrationService.getZohoAgentList(qParams).subscribe((res: any) => {
            const newAgent = res["data"].find(obj => obj.id == zohoCRMID)
            this.integrationLink = newAgent.main_integration_link
            this.initializeCRMTable();
            this.vsipEnabled = false;
            this.showModal = true;

          })

        }
        else if (res["error"].includes("agent_number")) {

          Swal.fire("Something went wrong !", "Agent number already exists.", "error")
        }
        else if (res["error"].includes("vsip_number")) {

          Swal.fire("Something went wrong !", "Vsip id already exists.", "error")
        }

      })
    }
  }

  markFormGroupAsTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(controlName => {
      const control = formGroup.get(controlName);

      if (control instanceof FormGroup) {
        this.markFormGroupAsTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  onModalClose() {
    this.showModal = false;
  }
  closeCRMModal() {
    this.onCRMActivationStep = false;
  }
  closeCRMConfigModal() {
    this.onConfigureCRMStep = false;
  }

  redirectToZoho() {
    window.location.href = this.integrationLink;
  }

  copyToClipBoard(text) {
    window.navigator['clipboard'].writeText(text)
    this.toolTip = true;
  }

  copyCRMLinkToClipBoard(text) {
    window.navigator['clipboard'].writeText(text)
    this.toolTip = true;
  }

  onPhoneBridgeActivate(integrationLink: string) {
    window.location.href = integrationLink;
  }


  activateClickToDial(id: string) {
    const data = { user_id: id, action: "enable" }
    this.zohoIntegrationService.switchClickToDial(data).subscribe((res) => {
      if (res["status"] == "1") {
        this.initializeCRMTable();
        Swal.fire("Success", "Click to dial activated successfully.", "success")
      }
      else {
        Swal.fire("Failed", res["message"], "error")
      }
    })
  }




  deActivateClickToDial(id: string) {
    const data = { user_id: id, action: "disable" }
    this.zohoIntegrationService.switchClickToDial(data).subscribe((res) => {
      if (res["status"] == "1") {
        this.initializeCRMTable();
        Swal.fire("Success", "Click to dial deactivated successfully.", "success")
      }
      else {
        Swal.fire("Failed", res["message"], "error")
      }
    })

  }

  onConfigureCRM(user_id: string) {
    this.onConfigureCRMStep = true;
    this.crmConfigUserId = user_id;
  }

  onCopyRedirectURI(redirectURI: string) {
    window.navigator['clipboard'].writeText(redirectURI)
  }

  onCRMActivation(integrationLink: string) {
    window.location.href = integrationLink;
  }

  onCRMConfigSubmit() {

    const data = {
      user_id: this.crmConfigUserId,
      client_id: this.crmConfigForm.value.client_id,
      client_secret: this.crmConfigForm.value.client_secret
    }

    this.zohoIntegrationService.addCrmConfig(data).subscribe((res: any) => {
      if (res["status"] == "1") {
        Swal.fire("Success", res["message"], "success");
        this.onConfigureCRMStep = false;
        this.initializeCRMTable();
      }
      else {
        Swal.fire("Something went wrong!", res["message"], "error");
      }
    })
  }

  crmActivationStep() {
    this.onCRMActivationStep = true;
  }

  onDeleteAgent(id: string) {
    this.onDelete = true;
    this.onDeleteCRMID = id;
  }

  onDeleteConfirm() {
    this.zohoIntegrationService.deleteZohoAgent(this.onDeleteCRMID).subscribe((res: any) => {
      if (res["status"] == "1") {
        this.initializeCRMTable();
        this.onDelete = false;
        Swal.fire("Success", "Agent deleted successfully", "success");
      }
      else {
        Swal.fire("Failed", res["message"], "error");
      }
    });
  }

  onDeleteDeny() {
    this.onDelete = false;
  }

  onVsipChange() {
    this.vsipEnabled = this.zohoForm.value.vsipEnabled
    const vsipIdControl = this.zohoForm.get("vsipId")
    if (this.vsipEnabled) {
      vsipIdControl.setValidators([Validators.required]);
    } else {
      vsipIdControl.clearValidators();
    }

    vsipIdControl.updateValueAndValidity();
  }

  onToggleIntegration() {
    const data = {
      SendDataToExternalAPI: this.sendDataToExternalAPIID,
      agent_name: this.userName,
      agent_number: this.phoneNumber.slice(-10),
      vsipEnabled: false,
      vsip_number: "",
      is_admin: true
    }

    

    if (!this.integrationEnabled) {
      if (this.adminUser) {
        window.location.href = this.adminUser.main_integration_link;
      } else {
        this.zohoIntegrationService.addZohoAgent(data).subscribe((res: any) => {
          if (res.status === "1") {
            window.location.href = res.data.main_integration_link;
          } else {            
            Swal.fire("Something went wrong!", "Failed to enable the inetegration.", "error")
          }

        });
      }
    } else {
      const data = {
        user_id: this.adminUser.id,
        action: "disable"
      }   

      Swal.fire({
        title: 'Are you sure ?',
        text: 'The integration will be deleted',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete it!',
        cancelButtonText: 'No, Cancel it!'
      }).then((result) => {
        if (result.value) {
          this.zohoIntegrationService.switchPhoneBridge(data).subscribe((res: any) => {
            if (res.status === "1") {
              Swal.fire("Success", "Zoho PhoneBridge Deactivated.", "success")
            } else {
              Swal.fire("Something went wrong!", "Failed to deactivate PhoneBridge.", "error")
              this.integrationEnabled = true;
            }
          })
        } else {
          this.integrationEnabled = true;
        }
      })     

    }

  }



}
