import { Component, OnInit, Input, Output } from "@angular/core";
import { LoginService } from "../login.service";
import { Router } from "@angular/router";
import { UsermanagementService } from "../usermanagement.service";

@Component({
  selector: "[app-sidebar]",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
	userData:any;
	isAdmin:string;
	permissions:any; 
  userType: any;
  public cadmin_a_permission_dashboard: string;
  public cadmin_a_addressbook: string;
	public cadmin_b_permission_usermanagement:string;
	public cadmin_c_permission_queue:string;
  public cadmin_d_permission_inboundroute:string;
	public cadmin_g_permission_ivr:string;
	public cadmin_h_permission_extension:string;
	public cadmin_i_voicerecording:string;
	public cadmin_n_reports:string;
	public cadmin_m_dialer:string;
	public cadmin_j_voicebroadcast:string;
	public cadmin_r_sms_configuration:string;
	public cadmin_t_didmanagement:string;
	public cadmin_s_creditsystem:string;
	public cadmin_t_inboundFeedback:string;
	public cadmin_a_agentUsers:string;
	public cadmin_v_voicemail:string;
  public cadmin_w_callTransfer: string
  public cadmin_x_integrations: string
  public cadmin_x_zoho_integration: string
  public cadmin_x_leadsuared: string
  public cadmin_x_kylas: string
  public cadmin_x_whatsapp: string


	classid=1;
	grp: any;
	userToggle: boolean;
	queueToggle: boolean;
	inboundToggle: boolean;
	creditToggle: boolean;
	ivrToggle: boolean;
	extensionToggle: boolean;
	reportToggle: boolean;
	dialerToggle: boolean;
	vbToggle: boolean;
  smsToggle: boolean;
  PushNotificationToggle: boolean;
	voicemailToggle:boolean=false;
	contactcentreToggle: boolean;
	autoCallToggle: boolean;
  callTransferToggle: boolean = false;
  integrationToggle: boolean = false;
  whatsappToggle: boolean = false;

	cadmin_a_autoCallingManagement: string;
	routerLinkActiveIsDisabled: boolean;
	dashboardOver: boolean = false;
	userMgmtOver: boolean = false;
  addressBookOver: boolean = false
	queueMgmt: boolean =  false;
	InboundMgmt: boolean =  false;
	creditMgmt: boolean =  false;
	IvrMgmt: boolean =  false;
	ExtensionMgmt: boolean =  false;
	VoiceRecMgmt: boolean =  false;
	ReportMgmt: boolean =  false;
	DialerMgmt: boolean =  false;
	contactcentreMgmt: boolean =  false;
	DIdMgmt: boolean =  false;
	VBMgmt: boolean =  false;
  SMSMgmt: boolean = false;
  PushNotificationMgmt: boolean = false;
  VoicemailMgmt: boolean = false;
	LogoutMgmt: boolean =  false;
	AutoCallMgmt: boolean =  false;
  callTranferMgmt : boolean = false;
  integrations: boolean = false;
  whatsapp: boolean = false;


	cadmin_f_matchpattern: string;
	cadmin_e_permission_timecondition: string;
	cadmin_o_inboundreport: string;
	cadmin_o_agentreport:string;
	cadmin_o_dialpadreport:string;
	cadmin_o_callerreport:string;
	cadmin_o_autocallreport:string;
	cadmin_q_dialerReport: string;
	cadmin_p_missedcallreport: string;
  cadmin_c_callbackreport:string;
  cadmin_o_livecallreport: string;
  cadmin_o_elasticreport: string;
  cadmin_o_callcountreport: string;
  cadmin_c_audioreport: string;
	cadmin_q_VBReport: string;
	cadmin_q_virtualSip: string;
	cadmin_q_contactcentre:string;
	virtualsipToggle: boolean;
	virtualSip: boolean =  false;
	cadmin_s_smsCount:string;	
  logout_data: any;
	sidebar=false;
  
  @Input() sidebar_state: any
	
	constructor(
		private loginservice: LoginService,
		private router:Router,
    private usermanagement: UsermanagementService
	) { }

	ngOnInit() {
		this.isAdmin = localStorage.getItem('isAdmin')
		this.userType=localStorage.getItem('userType')
		this.userData = this.loginservice.getUserdata(true);
		this.permissions = this.userData.permissions
		this.grp = this.userData.group
    this.usermanagement.get_sidebar_toggle_setting(this.userData.id, this.userData.usertype).subscribe((res:any) => {this.sidebar = res.sidebar_value})
		for (const perm of this.permissions)
		{
      if (this.userType == "virtualUser")
      {
        this.cadmin_n_reports = "true";
        this.cadmin_q_virtualSip = "true";
        this.cadmin_a_addressbook = "true";
      }

      if (perm.codename == 'cadmin_a_permission_dashboard')
      {
        this.cadmin_a_permission_dashboard = "true";
      }
      
      if (perm.codename == 'cadmin_a_addressbook')
      {
        this.cadmin_a_addressbook = "true";
      }

			if (perm.codename=='cadmin_b_permission_usermanagement' && this.userType!="agentUser"  && this.userType!="dialerUser")
			{
				this.cadmin_b_permission_usermanagement="true";
			}
			if (perm.codename=='cadmin_c_permission_queue'  && this.userType!="agentUser" && this.userType!="dialerUser")
			{
				this.cadmin_c_permission_queue="true";
			}
			if (perm.codename=='cadmin_d_permission_inboundroute'  && this.userType!="agentUser" && this.userType!="dialerUser")
			{
				this.cadmin_d_permission_inboundroute="true";
			}
			if (perm.codename=='cadmin_g_permission_ivr'  && this.userType!="agentUser" && this.userType!="dialerUser")
			{
				this.cadmin_g_permission_ivr="true";
			}
			if (perm.codename=='cadmin_h_permission_extension'  && this.userType!="agentUser" && this.userType!="dialerUser")
			{
				this.cadmin_h_permission_extension="true";
			}
			if (perm.codename=='cadmin_i_voicerecording'  && this.userType!="agentUser" && this.userType!="dialerUser")
			{
				this.cadmin_i_voicerecording="true";
			}
			if (perm.codename=='cadmin_m_dialer'  && this.userType!="agentUser" )
			{
				this.cadmin_m_dialer="true";
			}
			if (perm.codename=='cadmin_n_reports')
      {
				this.cadmin_n_reports="true";
			}
			if (perm.codename=='cadmin_j_voicebroadcast'  && this.userType!="agentUser" && this.userType!="dialerUser")
			{
				this.cadmin_j_voicebroadcast="true";
			}
			if (perm.codename=='cadmin_s_creditsystem'  && this.userType!="agentUser" && this.userType!="dialerUser")
			{
				this.cadmin_s_creditsystem="true";
			}

			if (perm.codename=='cadmin_r_sms_configuration'  && this.userType!="agentUser" && this.userType!="dialerUser")
			{
				this.cadmin_r_sms_configuration="true";
			}
			if (perm.codename=='cadmin_t_didmanagement'  && this.userType!="agentUser" && this.userType!="dialerUser")
			{
				this.cadmin_t_didmanagement="true";
			}
			if (perm.codename=='cadmin_t_inboundFeedback'  && this.userType!="agentUser" && this.userType!="dialerUser")
			{
				this.cadmin_t_inboundFeedback="true";
			}
			if (perm.codename=='cadmin_a_agentUsers'  && this.userType!="agentUser" && this.userType!="dialerUser")
			{
				this.cadmin_a_agentUsers="true";
			}
			if (perm.codename=='cadmin_a_autoCallingManagement'  && this.userType!="agentUser" && this.userType!="dialerUser")
			{
				this.cadmin_a_autoCallingManagement="true";
			}

			if (perm.codename=='cadmin_f_matchpattern'  && this.userType!="agentUser" && this.userType!="dialerUser")
			{
				this.cadmin_f_matchpattern="true";
			}
			if (perm.codename=='cadmin_e_permission_timecondition' && this.userType!="agentUser" && this.userType!="dialerUser")
			{
				this.cadmin_e_permission_timecondition="true";
			}
			if (perm.codename=='cadmin_o_inboundreport')
			{
				this.cadmin_o_inboundreport="true";
			}
      if (perm.codename=='cadmin_c_callbackreport')
      {
        this.cadmin_c_callbackreport="true";
      
      }
			if (perm.codename=='cadmin_o_agentreport')
					{
						this.cadmin_o_agentreport="true";
			}

			if (perm.codename=='cadmin_o_dialpadreport')
					{ 
						this.cadmin_o_dialpadreport="true";
			}

		
			if (perm.codename=='cadmin_o_callerreport' && this.userType!="agentUser" && this.userType!="dialerUser")
					{
						this.cadmin_o_callerreport="true";
			}
			if (perm.codename=='cadmin_q_dialerReport')
			{
				this.cadmin_q_dialerReport="true";
			}
			
			if (perm.codename=='cadmin_o_autocallreport')
			{
				this.cadmin_o_autocallreport="true";
			}


			if (perm.codename=='cadmin_o_livecallreport')
			{
				this.cadmin_o_livecallreport="true";
      }
      
      if (perm.codename == 'cadmin_o_elasticreport')
      {
        this.cadmin_o_elasticreport = 'true';
      }

      if (perm.codename == 'cadmin_o_callcountreport')
      {
        this.cadmin_o_callcountreport = 'true';
      }
      if (perm.codename == 'cadmin_c_audioreport')
      {
        this.cadmin_c_audioreport = 'true';
      }

			
			if (perm.codename=='cadmin_p_missedcallreport'  && this.userType!="agentUser" && this.userType!="dialerUser")
			{
				this.cadmin_p_missedcallreport="true";
			}

			if (perm.codename=='cadmin_q_VBReport'  && this.userType!="agentUser" && this.userType!="dialerUser")
			{
				this.cadmin_q_VBReport="true";
			}

			if (perm.codename=='cadmin_q_virtualSip'  && this.userType!="agentUser" && this.userType!="dialerUser" && this.userType!="virtualUser")
			{
				this.cadmin_q_virtualSip="true";
			}

			if (perm.codename=='cadmin_s_smsCount'  && this.userType!="agentUser" && this.userType!="dialerUser")
			{
				this.cadmin_s_smsCount="true";
			}
			if (perm.codename=='cadmin_q_contactcentre')
			{
				this.cadmin_q_contactcentre="true";
			}
			if (perm.codename=='cadmin_v_voicemail' && this.userType!="agentUser" && this.userType!="dialerUser" && this.userType!="virtualUser")
			{
				this.cadmin_v_voicemail="true";
			}
      if (perm.codename=='cadmin_w_callTransfer' && this.userType!="agentUser" && this.userType!="dialerUser" && this.userType!="virtualUser")
			{
				this.cadmin_w_callTransfer="true";
			}

      if (perm.codename=='cadmin_x_integrations' && this.userType!="agentUser" && this.userType!="dialerUser" && this.userType!="virtualUser")
			{
				this.cadmin_x_integrations="true";
      }
      
      if (perm.codename == 'cadmin_x_zoho_integration' && this.userType != "agentUser" && this.userType != "dialerUser" && this.userType != "virtualUser") {
        this.cadmin_x_zoho_integration = "true";
      }

      if (perm.codename == 'cadmin_x_leadsuared' && this.userType != "agentUser" && this.userType != "dialerUser" && this.userType != "virtualUser") {
        this.cadmin_x_leadsuared = "true";
      }

      if (perm.codename == 'cadmin_x_kylas' && this.userType != "agentUser" && this.userType != "dialerUser" && this.userType != "virtualUser") {
        this.cadmin_x_kylas = "true";
      }

      if (perm.codename == 'cadmin_x_whatsapp' && this.userType != "agentUser" && this.userType != "dialerUser" && this.userType != "virtualUser") {
        this.cadmin_x_whatsapp = "true";
      }

    }
    
	}

  addClass(id: any) {
    this.classid = id;
  }

  OnLogOut() {
    window.localStorage.clear();
    this.router.navigate(["/"]);
    window.location.reload();
    this.logout_data = {
      username: this.userData.name,
      user_type: this.userType,
    };
    this.loginservice.doLogout(this.logout_data).subscribe((datas: any) => {});
  }

  useractive() {
    if (this.userToggle == false) {
      this.userToggle = true;
      this.userMgmtOver = true;
    } else {
      this.userToggle = false;
    }
  }

  queueActive() {
    if (this.queueToggle == false) {
      this.queueToggle = true;
    } else {
      this.queueToggle = false;
    }
  }

  inboundActive() {
    if (this.inboundToggle == false) {
      this.inboundToggle = true;
    } else {
      this.inboundToggle = false;
    }
  }

  creditActive() {
    if (this.creditToggle == false) {
      this.creditToggle = true;
    } else {
      this.creditToggle = false;
    }
  }

  IvrActive() {
    if (this.ivrToggle == false) {
      this.ivrToggle = true;
    } else {
      this.ivrToggle = false;
    }
  }

  extensionActive() {
    if (this.extensionToggle == false) {
      this.extensionToggle = true;
    } else {
      this.extensionToggle = false;
    }
  }

  reportActive() {
    if (this.reportToggle == false) {
      this.reportToggle = true;
      console.log(this.reportToggle);
    } else {
      this.reportToggle = false;
    }
  }

  dialerActive() {
    if (this.dialerToggle == false) {
      this.dialerToggle = true;
    } else {
      this.dialerToggle = false;
    }
  }

  contactcentreActive() {
    if (this.contactcentreToggle == false) {
      this.contactcentreToggle = true;
    } else {
      this.contactcentreToggle = false;
    }
  }

  vbActive() {
    if (this.vbToggle == false) {
      this.vbToggle = true;
    } else {
      this.vbToggle = false;
    }
  }

  smsActive() {
    if (this.smsToggle == false) {
      this.smsToggle = true;
    } else {
      this.smsToggle = false;
    }
  }

  pushnotificationActive() {
    if (this.PushNotificationToggle == false) {
      this.PushNotificationToggle = true;
    } else {
      this.PushNotificationToggle = false;
    }
  }


  autoCallActive() {
    if (this.autoCallToggle == false) {
      this.autoCallToggle = true;
    } else {
      this.autoCallToggle = false;
    }
  }

  virtualsipActive() {
    if (this.virtualsipToggle == false) {
      this.virtualsipToggle = true;
    } else {
      this.virtualsipToggle = false;
    }
  }

  VoicemailActive() {
    if (this.voicemailToggle == false) {
      this.voicemailToggle = true;
    } else {
      this.voicemailToggle = false;
    }
  }

  callTransferActive(){
    if (this.callTransferToggle == false){
      this.callTransferToggle = true
    }
    else{
      this.callTransferToggle = false
    }

    
  }

  integrationsActive(){
    if (this.integrationToggle == false){
      this.integrationToggle = true
    }
    else{
      this.integrationToggle = false
    }

    
  }

  whatsappActive() {
    if (this.whatsappToggle == false) {
      this.whatsappToggle = true
    }
    else {
      this.whatsappToggle = false
    }


  }



  mouse_overDashboard() {
    this.dashboardOver = true;
    this.userMgmtOver = false;
    this.queueMgmt = false;
    this.InboundMgmt = false;
    this.creditMgmt = false;
    this.IvrMgmt = false;
    this.ExtensionMgmt = false;
    this.VoiceRecMgmt = false;
    this.ReportMgmt = false;
    this.DialerMgmt = false;
    this.DIdMgmt = false;
    this.VBMgmt = false;
    this.SMSMgmt = false;
    this.PushNotificationMgmt = false;
    this.LogoutMgmt = false;
    this.AutoCallMgmt = false;
    this.contactcentreMgmt = false;
    this.VoicemailMgmt = false;
    this.callTranferMgmt = false;
    this.integrations = false;
  }
  mouseLeaveDashboard() {
    this.dashboardOver = false;
  }

  mouse_overUsermanagement() {
    this.userMgmtOver = true;
    this.dashboardOver = false;
    this.queueMgmt = false;
    this.InboundMgmt = false;
    this.creditMgmt = false;
    this.IvrMgmt = false;
    this.ExtensionMgmt = false;
    this.VoiceRecMgmt = false;
    this.ReportMgmt = false;
    this.DialerMgmt = false;
    this.DIdMgmt = false;
    this.VBMgmt = false;
    this.SMSMgmt = false;
    this.PushNotificationMgmt = false;
    this.LogoutMgmt = false;
    this.AutoCallMgmt = false;
    this.contactcentreMgmt = false;
    this.VoicemailMgmt = false;
    this.callTranferMgmt = false;
    this.integrations = false;
  }
  mouse_LeaveUsermanagement() {
    this.userMgmtOver = false;
  }

  mouse_overAddressBook(){
    this.addressBookOver = true
  }

  mouse_LeaveAddressBook(){
    this.addressBookOver = false
  }

  mouse_overQueue() {
    this.queueMgmt = true;
    this.dashboardOver = false;
    this.userMgmtOver = false;
    this.InboundMgmt = false;
    this.creditMgmt = false;
    this.IvrMgmt = false;
    this.ExtensionMgmt = false;
    this.VoiceRecMgmt = false;
    this.ReportMgmt = false;
    this.DialerMgmt = false;
    this.DIdMgmt = false;
    this.VBMgmt = false;
    this.SMSMgmt = false;
    this.PushNotificationMgmt = false;
    this.LogoutMgmt = false;
    this.AutoCallMgmt = false;
    this.contactcentreMgmt = false;
    this.VoicemailMgmt = false;
    this.callTranferMgmt = false;
    this.integrations = false;
  }
  mouse_LeaveQueue() {
    this.queueMgmt = false;
  }

  mouse_overInbound() {
    this.InboundMgmt = true;
    this.dashboardOver = false;
    this.userMgmtOver = false;
    this.queueMgmt = false;
    this.creditMgmt = false;
    this.IvrMgmt = false;
    this.ExtensionMgmt = false;
    this.VoiceRecMgmt = false;
    this.ReportMgmt = false;
    this.DialerMgmt = false;
    this.DIdMgmt = false;
    this.VBMgmt = false;
    this.SMSMgmt = false;
    this.PushNotificationMgmt = false;
    this.LogoutMgmt = false;
    this.AutoCallMgmt = false;
    this.contactcentreMgmt = false;
    this.VoicemailMgmt = false;
    this.callTranferMgmt = false
    this.integrations = false;
  }
  mouse_LeaveInbound() {
    this.InboundMgmt = false;
  }

  mouse_overCredit() {
    this.creditMgmt = true;
    this.dashboardOver = false;
    this.userMgmtOver = false;
    this.queueMgmt = false;
    this.InboundMgmt = false;
    this.IvrMgmt = false;
    this.ExtensionMgmt = false;
    this.VoiceRecMgmt = false;
    this.ReportMgmt = false;
    this.DialerMgmt = false;
    this.DIdMgmt = false;
    this.VBMgmt = false;
    this.SMSMgmt = false;
    this.PushNotificationMgmt = false;
    this.LogoutMgmt = false;
    this.AutoCallMgmt = false;
    this.contactcentreMgmt = false;
    this.VoicemailMgmt = false;
    this.callTranferMgmt = false;
    this.integrations = false;
  }
  mouse_LeaveCredit() {
    this.creditMgmt = false;
  }

  mouse_overIvr() {
    this.IvrMgmt = true;
    this.dashboardOver = false;
    this.userMgmtOver = false;
    this.queueMgmt = false;
    this.InboundMgmt = false;
    this.creditMgmt = false;
    this.ExtensionMgmt = false;
    this.VoiceRecMgmt = false;
    this.ReportMgmt = false;
    this.DialerMgmt = false;
    this.DIdMgmt = false;
    this.VBMgmt = false;
    this.SMSMgmt = false;
    this.PushNotificationMgmt = false;
    this.LogoutMgmt = false;
    this.AutoCallMgmt = false;
    this.contactcentreMgmt = false;
    this.VoicemailMgmt = false;
    this.callTranferMgmt = false
    this.integrations = false;
  }
  mouse_LeaveIvr() {
    this.IvrMgmt = false;
  }

  mouse_overExtension() {
    this.ExtensionMgmt = true;
    this.dashboardOver = false;
    this.userMgmtOver = false;
    this.queueMgmt = false;
    this.InboundMgmt = false;
    this.creditMgmt = false;
    this.IvrMgmt = false;
    this.VoiceRecMgmt = false;
    this.ReportMgmt = false;
    this.DialerMgmt = false;
    this.DIdMgmt = false;
    this.VBMgmt = false;
    this.SMSMgmt = false;
    this.PushNotificationMgmt = false;
    this.LogoutMgmt = false;
    this.AutoCallMgmt = false;
    this.contactcentreMgmt = false;
    this.VoicemailMgmt = false;
    this.callTranferMgmt = false
    this.integrations = false;
  }
  mouse_LeaveExtension() {
    this.ExtensionMgmt = false;
  }

  mouse_overVoiceRec() {
    this.VoiceRecMgmt = true;
    this.dashboardOver = false;
    this.userMgmtOver = false;
    this.queueMgmt = false;
    this.InboundMgmt = false;
    this.creditMgmt = false;
    this.IvrMgmt = false;
    this.ExtensionMgmt = false;
    this.ReportMgmt = false;
    this.DialerMgmt = false;
    this.DIdMgmt = false;
    this.VBMgmt = false;
    this.SMSMgmt = false;
    this.PushNotificationMgmt = false;
    this.LogoutMgmt = false;
    this.AutoCallMgmt = false;
    this.contactcentreMgmt = false;
    this.VoicemailMgmt = false;
    this.callTranferMgmt = false;
    this.integrations = false;
  }
  mouse_LeaveVoiceRec() {
    this.VoiceRecMgmt = false;
  }

  mouse_overReport() {
    this.ReportMgmt = true;
    this.dashboardOver = false;
    this.userMgmtOver = false;
    this.queueMgmt = false;
    this.InboundMgmt = false;
    this.creditMgmt = false;
    this.IvrMgmt = false;
    this.ExtensionMgmt = false;
    this.VoiceRecMgmt = false;
    this.DialerMgmt = false;
    this.DIdMgmt = false;
    this.VBMgmt = false;
    this.SMSMgmt = false;
    this.PushNotificationMgmt = false;
    this.LogoutMgmt = false;
    this.AutoCallMgmt = false;
    this.contactcentreMgmt = false;
    this.VoicemailMgmt = false;
    this.callTranferMgmt = false;
    this.integrations = false;
  }
  mouse_LeaveReport() {
    this.ReportMgmt = false;
  }

  mouse_overDialer() {
    this.DialerMgmt = true;
    this.dashboardOver = false;
    this.userMgmtOver = false;
    this.queueMgmt = false;
    this.InboundMgmt = false;
    this.creditMgmt = false;
    this.IvrMgmt = false;
    this.ExtensionMgmt = false;
    this.VoiceRecMgmt = false;
    this.ReportMgmt = false;
    this.DIdMgmt = false;
    this.VBMgmt = false;
    this.SMSMgmt = false;
    this.PushNotificationMgmt = false;
    this.LogoutMgmt = false;
    this.AutoCallMgmt = false;
    this.contactcentreMgmt = false;
    this.VoicemailMgmt = false;
    this.callTranferMgmt = false
    this.integrations = false
  }
  mouse_LeaveDialer() {
    this.DialerMgmt = false;
  }

  mouse_overcontactcentre() {
    this.DialerMgmt = false;
    this.dashboardOver = false;
    this.userMgmtOver = false;
    this.queueMgmt = false;
    this.InboundMgmt = false;
    this.creditMgmt = false;
    this.IvrMgmt = false;
    this.ExtensionMgmt = false;
    this.VoiceRecMgmt = false;
    this.ReportMgmt = false;
    this.DIdMgmt = false;
    this.VBMgmt = false;
    this.SMSMgmt = false;
    this.PushNotificationMgmt = false;
    this.LogoutMgmt = false;
    this.AutoCallMgmt = false;
    this.contactcentreMgmt = true;
    this.VoicemailMgmt = false;
    this.callTranferMgmt = false;
    this.integrations= false;
  }
  mouse_Leavecontactcentre() {
    this.contactcentreMgmt = false;
  }

  mouse_overDId() {
    this.DIdMgmt = true;
    this.dashboardOver = false;
    this.userMgmtOver = false;
    this.queueMgmt = false;
    this.InboundMgmt = false;
    this.creditMgmt = false;
    this.IvrMgmt = false;
    this.ExtensionMgmt = false;
    this.VoiceRecMgmt = false;
    this.ReportMgmt = false;
    this.DialerMgmt = false;
    this.VBMgmt = false;
    this.SMSMgmt = false;
    this.PushNotificationMgmt = false;
    this.LogoutMgmt = false;
    this.AutoCallMgmt = false;
    this.contactcentreMgmt = false;
    this.VoicemailMgmt = false;
    this.callTranferMgmt = false;
    this.integrations = false;
  }
  mouse_LeaveDId() {
    this.DIdMgmt = false;
  }

  mouse_overVB() {
    this.VBMgmt = true;
    this.dashboardOver = false;
    this.userMgmtOver = false;
    this.queueMgmt = false;
    this.InboundMgmt = false;
    this.creditMgmt = false;
    this.IvrMgmt = false;
    this.ExtensionMgmt = false;
    this.VoiceRecMgmt = false;
    this.ReportMgmt = false;
    this.DialerMgmt = false;
    this.DIdMgmt = false;
    this.SMSMgmt = false;
    this.PushNotificationMgmt = false;
    this.LogoutMgmt = false;
    this.AutoCallMgmt = false;
    this.contactcentreMgmt = false;
    this.VoicemailMgmt = false;
    this.callTranferMgmt = false;
    this.integrations = false;
  }
  mouse_LeaveVB() {
    this.VBMgmt = false;
  }

  mouse_overSMS() {
    this.SMSMgmt = true;
    this.PushNotificationMgmt = false;
    this.dashboardOver = false;
    this.userMgmtOver = false;
    this.queueMgmt = false;
    this.InboundMgmt = false;
    this.creditMgmt = false;
    this.IvrMgmt = false;
    this.ExtensionMgmt = false;
    this.VoiceRecMgmt = false;
    this.ReportMgmt = false;
    this.DialerMgmt = false;
    this.DIdMgmt = false;
    this.VBMgmt = false;
    this.LogoutMgmt = false;
    this.AutoCallMgmt = false;
    this.contactcentreMgmt = false;
    this.VoicemailMgmt = false;
    this.callTranferMgmt = false;
    this.integrations = false;
  }
  mouse_LeaveSMS() {
    this.SMSMgmt = false;
  }


  mouse_overPushNotification() {
    this.PushNotificationMgmt = true;
    this.dashboardOver = false;
    this.userMgmtOver = false;
    this.queueMgmt = false;
    this.InboundMgmt = false;
    this.creditMgmt = false;
    this.IvrMgmt = false;
    this.ExtensionMgmt = false;
    this.VoiceRecMgmt = false;
    this.ReportMgmt = false;
    this.DialerMgmt = false;
    this.DIdMgmt = false;
    this.VBMgmt = false;
    this.SMSMgmt = false;
    this.LogoutMgmt = false;
    this.AutoCallMgmt = false;
    this.contactcentreMgmt = false;
    this.VoicemailMgmt = false;
    this.callTranferMgmt = false;
    this.integrations = false;
  }

  mouse_LeavePushNotification() {
    this.PushNotificationMgmt = false;
  }



  mouse_overVociemail() {
    this.VoicemailMgmt = true; 
    this.SMSMgmt = false;
    this.PushNotificationMgmt = false;
    this.dashboardOver = false;
    this.userMgmtOver = false;
    this.queueMgmt = false;
    this.InboundMgmt = false;
    this.creditMgmt = false;
    this.IvrMgmt = false;
    this.ExtensionMgmt = false;
    this.VoiceRecMgmt = false;
    this.ReportMgmt = false;
    this.DialerMgmt = false;
    this.DIdMgmt = false;
    this.VBMgmt = false;
    this.LogoutMgmt = false;
    this.AutoCallMgmt = false;
    this.contactcentreMgmt = false;
    this.callTranferMgmt = false;
  }
  mouse_LeaveVociemail() {
    this.VoicemailMgmt = false;
  }

  mouse_overLogout() {
    this.LogoutMgmt = true;
    this.dashboardOver = false;
    this.userMgmtOver = false;
    this.queueMgmt = false;
    this.InboundMgmt = false;
    this.creditMgmt = false;
    this.IvrMgmt = false;
    this.ExtensionMgmt = false;
    this.VoiceRecMgmt = false;
    this.ReportMgmt = false;
    this.DialerMgmt = false;
    this.DIdMgmt = false;
    this.VBMgmt = false;
    this.SMSMgmt = false;
    this.PushNotificationMgmt = false;
    this.AutoCallMgmt = false;
    this.contactcentreMgmt = false;
    this.callTranferMgmt = false;
    this.integrations = false;
    
  }
  mouse_LeaveLogout() {
    this.LogoutMgmt = false;
  }

  mouse_overAutoCall() {
    this.AutoCallMgmt = true;
    this.dashboardOver = false;
    this.userMgmtOver = false;
    this.queueMgmt = false;
    this.InboundMgmt = false;
    this.creditMgmt = false;
    this.IvrMgmt = false;
    this.ExtensionMgmt = false;
    this.VoiceRecMgmt = false;
    this.ReportMgmt = false;
    this.DialerMgmt = false;
    this.DIdMgmt = false;
    this.VBMgmt = false;
    this.SMSMgmt = false;
    this.PushNotificationMgmt = false;
    this.LogoutMgmt = false;
    this.contactcentreMgmt = false;
    this.callTranferMgmt = false;
    this.integrations = false;
  }
  mouse_LeaveAutoCall() {
    this.AutoCallMgmt = false;
  }

  mouse_overvirtualsip() {
    this.virtualSip = true;
    this.AutoCallMgmt = false;
    this.dashboardOver = false;
    this.userMgmtOver = false;
    this.queueMgmt = false;
    this.InboundMgmt = false;
    this.creditMgmt = false;
    this.IvrMgmt = false;
    this.ExtensionMgmt = false;
    this.VoiceRecMgmt = false;
    this.ReportMgmt = false;
    this.DialerMgmt = false;
    this.DIdMgmt = false;
    this.VBMgmt = false;
    this.SMSMgmt = false;
    this.PushNotificationMgmt = false;
    this.LogoutMgmt = false;
    this.contactcentreMgmt = false;
    this.callTranferMgmt = false;
    this.integrations = false;
  }
  mouse_Leavevirtualsip() {
    this.virtualSip = false;
  }

  mouse_OverCallTransfer(){
    this.callTranferMgmt = true
    this.virtualSip = false;
    this.AutoCallMgmt = false;
    this.dashboardOver = false;
    this.userMgmtOver = false;
    this.queueMgmt = false;
    this.InboundMgmt = false;
    this.creditMgmt = false;
    this.IvrMgmt = false;
    this.ExtensionMgmt = false;
    this.VoiceRecMgmt = false;
    this.ReportMgmt = false;
    this.DialerMgmt = false;
    this.DIdMgmt = false;
    this.VBMgmt = false;
    this.SMSMgmt = false;
    this.PushNotificationMgmt = false;
    this.LogoutMgmt = false;
    this.contactcentreMgmt = false;
    this.integrations = false;
  }
  mouse_LeaveCallTransfer(){
    this.callTranferMgmt = false;
  }

  mouse_OverIntegrations(){
    this.callTranferMgmt = false
    this.virtualSip = false;
    this.AutoCallMgmt = false;
    this.dashboardOver = false;
    this.userMgmtOver = false;
    this.queueMgmt = false;
    this.InboundMgmt = false;
    this.creditMgmt = false;
    this.IvrMgmt = false;
    this.ExtensionMgmt = false;
    this.VoiceRecMgmt = false;
    this.ReportMgmt = false;
    this.DialerMgmt = false;
    this.DIdMgmt = false;
    this.VBMgmt = false;
    this.SMSMgmt = false;
    this.PushNotificationMgmt = false;
    this.LogoutMgmt = false;
    this.contactcentreMgmt = false;
    this.integrations = true;
  }
  mouse_LeaveIntegrations(){
    this.integrations = false;
  }

  mouse_OverWhatsapp() {
    this.callTranferMgmt = false
    this.virtualSip = false;
    this.AutoCallMgmt = false;
    this.dashboardOver = false;
    this.userMgmtOver = false;
    this.queueMgmt = false;
    this.InboundMgmt = false;
    this.creditMgmt = false;
    this.IvrMgmt = false;
    this.ExtensionMgmt = false;
    this.VoiceRecMgmt = false;
    this.ReportMgmt = false;
    this.DialerMgmt = false;
    this.DIdMgmt = false;
    this.VBMgmt = false;
    this.SMSMgmt = false;
    this.PushNotificationMgmt = false;
    this.LogoutMgmt = false;
    this.contactcentreMgmt = false;
    this.integrations = false;
    this.whatsapp = true
  
  }
  mouse_LeaveWhatsapp() {
    this.whatsapp = false;
  }

  ngOnChanges() {
    if (this.sidebar_state == "True") {
      this.sidebar = true;
    } else if (this.sidebar_state == "False") {
      this.sidebar = false;
    }
  }

}
