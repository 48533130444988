import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { merittoagents } from './merittoagents.service';
import Swal from 'sweetalert2';
import { LoginService } from '../login.service';
@Component({
  selector: 'app-add-merittoagents',
  templateUrl: './add-merittoagents.component.html',
  styleUrls: ['./add-merittoagents.component.css']
})
export class AddMerittoagentsComponent implements OnInit {
  merittoForm: FormGroup;
  userProfile: any;
  merittoagentlist: any[] = [];
  userType: any;
  grp: any;
  userData: any;
  UserProfileId: any = null;
  userSelected: boolean = false;  // This will track if a user was selected
  UserProfileIdSelected: boolean = false;

  constructor(
    private meritoService:merittoagents,
    private fb: FormBuilder,
    private loginservice: LoginService,


  ) {
    this.merittoForm = this.fb.group({
      agentName: ["",Validators.required],
      agentEmail: ["", [Validators.required, Validators.email]],
      agentNumber: ["", [Validators.required, Validators.pattern(/^\d{10}$/)]],
      
      
    })
   }

  ngOnInit() {
    this.userProfile = JSON.parse(localStorage.getItem("userdata"))  
    this.userType = localStorage.getItem('userType')
    this.userData = this.loginservice.getUserdata(true);
    this.UserProfileId = this.userProfile ? this.userProfile.id : null;



    this.grp = this.userData.group

    if (!this.grp.includes('superadmin')) {
      this.UserProfileId = this.userProfile ? this.userProfile.id : null;
    }

    this.UserProfileIdSelected = false;  // Reset the flag initially


    this.getmeritoagentList()
  }


  onUserSelect(event: any) {
    this.UserProfileIdSelected = !!event;  // Ensure the flag is set when a user is selected
  }

  getmeritoagentList() {
    this.meritoService.getMerittoagentList().subscribe((res: any) => {
      if (res.status === "1") {
        this.merittoagentlist = res.data;
      }
      else {
        console.log('Failed to fetch agents');
      }
    },
      (error) => {
        console.log('Error fetching agents:', error);
      })
  }



  customerEvent(selectedUserId: number) {
    this.UserProfileId = selectedUserId;  // Update UserProfileId when a new user is selected
    this.UserProfileIdSelected = !!selectedUserId;  // Set the flag indicating a user has been selected
  }


  onSubmit() {
    if (this.merittoForm.valid) {
      // Superadmin-specific validation
      if (this.grp.includes('superadmin') && !this.UserProfileIdSelected) {
        Swal.fire("Warning", "Please select a user before submitting.", "warning");
        return;  // Stop the form submission if no user is selected
      }

      const data = {
        name: this.merittoForm.value.agentName,
        user: this.UserProfileId,  // Use the selected user ID
        agent_email: this.merittoForm.value.agentEmail,
        agent_number: this.merittoForm.value.agentNumber
      };

      console.log("Submitting data:", data);

      // Call the service to submit the data
      this.meritoService.addMerittoagents(data).subscribe(
        (res: any) => {
          if (res.status === "1") {
            Swal.fire("Success", "Agent added successfully");
            this.merittoForm.reset();
            this.getmeritoagentList();
          } else {
            Swal.fire("Something went wrong");
          }
        },
        (error: any) => {
          if (error.status === 400 && error.error && error.error.errors) {
            const errors = error.error.errors;
            let errorMessage = '';

            if (errors.name && errors.name[0] === "meritto agents with this name already exists.") {
              errorMessage += "An agent with this name already exists.\n";
            }
            if (errors.agent_number && errors.agent_number[0] === "meritto agents with this agent number already exists.") {
              errorMessage += "An agent with this agent number already exists.\n";
            }
            Swal.fire("Duplicate Entry", errorMessage.trim(), "warning");
          } else {
            Swal.fire("Error", "Something went wrong while adding the agent.", "error");
          }
        }
      );
    }
  }



  onDeletmerittoagent(id: number) {
    Swal.fire({
      title: "Are you sure ?",
      text: "The agent will be deleted.",
      showCancelButton: true,
      confirmButtonText: "Yes, Delete agent.",
      cancelButtonText: "No,Dont delete.",
    }).then((resp) => {
      if (!resp.dismiss) {
        this.meritoService.deletegagent(id).subscribe((res: any) => {
          if (res.status=== "1") {
            Swal.fire("Success", res["message"], "success")
            this.getmeritoagentList();
          }
        })
      }
    })
    
  }
}
