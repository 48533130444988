import { Component, OnInit } from "@angular/core";
import { log } from "console";
import { logWarnings } from "protractor/built/driverProviders";
import { HttpClient } from "@angular/common/http";
import { ExcelService } from "../inbound-report/excel.service";
import { DatePipe } from "@angular/common";
import { LoginService } from "../login.service";
import { NotificationService } from "../notification/notification.service";
import { forEach } from "jszip";
import { Data } from "./interface";
import { ReportService } from "./report.service";
import * as jsPDF from "jspdf";
import * as saveAs from "file-saver";
import { HttpEventType, HttpEvent } from "@angular/common/http";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import Swal from 'sweetalert2';
import { API_URL } from "src/global";



@Component({
  selector: "app-elastic-report",
  templateUrl: "./elastic-report.component.html",
  styleUrls: ["./elastic-report.component.css"],
  providers: [DatePipe],
})


export class ElasticReportComponent implements OnInit {
  private _value: number = 0;
  public cadmin_q_QueueTransfer = false;
  get value(): number {
    return this._value;
  }

  set value(value: number) {
    if (!isNaN(value) && value <= 100) {
      this._value = value;
    }
  }

  constructor(
    private http: HttpClient,
    private reportservice: ReportService,
    private excelService: ExcelService,
    private datePipe: DatePipe,
    private loginservice: LoginService,
    private notifyService: NotificationService,
    private spinnerService: Ng4LoadingSpinnerService,
  ) {}

  ElasticReportData: any = new Array();
  dropdownList = [];
  selectedItems = [];
  inputValue = "";
  fromdate: string = "";
  todate: string = "";
  isInputDisabled = false;
  isInputValid = true;
  tableData = [];
  dropdownSettings = {};
  pagination = { current_page: 0, total_page: 0 };
  user_data = { user_type: "", user_data: {} };
  payload = [];
  userData: any;
  permissions: any;
  userType: any;
  searchText: String;
  callStatus: String;
  ExcelPdfReportData: any = new Array();
  wordarray: any = new Array();
  isShownprogressbar = false;
  public id: any = 0;
  progress: number = 0;
  myDate = new Date();
  today = new Date(this.myDate.setDate(this.myDate.getDate()));
  today_informat = this.datePipe.transform(this.today, "yyyy-MM-dd");
  inputPlaceHolder: String = "Enter Value";
  agentList = [];


   // Settingsforcomment
   commentopen:boolean=false;
   comment:any
   call_id:any;


  ngOnInit() {
    // this.userData = this.loginservice.getUserdata(true);
    // this.permissions = this.userData.permissions
    this.user_data.user_type = localStorage.getItem("userType");
    this.user_data.user_data = JSON.parse(localStorage.getItem("userdata"));

    this.dropdownList = [
      { id: 1, itemName: "Incoming" },
      { id: 2, itemName: "Outgoing" },
      { id: 3, itemName: "Callback" },
      { id: 4, itemName: "DID" },
      { id: 5, itemName: "Customer No" },
      { id: 6, itemName: "Agent Number" },
      { id: 7, itemName: "Agent Name" },
      { id: 8, itemName: "Department Name" },
      { id: 9, itemName: "Total Duration >" },
      { id: 10, itemName: "Total Duration <" },
      { id: 11, itemName: "Call Duration >" },
      { id: 12, itemName: "Call Duration <" },
      { id: 13, itemName: "DTMF" },
      { id: 14, itemName: "Status : Answered" },
      { id: 15, itemName: "Status : No Answer" },
      { id: 16, itemName: "Status : Busy" },
      { id: 17, itemName: "Status : Unavailable" },
      { id: 18, itemName: "Status : Terminate" },
      { id: 19, itemName: "Status : No Input" },
      { id: 20, itemName: "Status : No Channel" },
    ];
    this.selectedItems = [];
    this.dropdownSettings = {
      singleSelection: true,
      text: "Select Filters",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      classes: "text-lg",
    };
    
    this.reportservice.getAgentList(this.user_data.user_data["id"], this.user_data.user_type).
    subscribe((res:any)=>{
      if (res.status == "1"){
        this.agentList = res.data       
      }
    })
  }
  // Droop Down Methods
  onItemSelect(item: any) {
    if (item.id > 0 && item.id <= 3) {
      this.isInputDisabled = true;
    } else if (item.id > 13 && item.id <= 20) {

      this.isInputDisabled = true;
    } else {
      this.isInputDisabled = false;
    }

    if(item.id >= 9 && item.id <=12){     
      this.inputPlaceHolder = "In seconds"
    }
  }
  OnItemDeSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onDeSelectAll(items: any) {
    this.isInputDisabled = false;
  }

  // Start Table Methods
  generateFilterTable() {
    if (this.fromdate != "" || this.todate != "") {
      // this.inputValue = "";
      const from_date = this.datePipe.transform(this.fromdate, "yyyy-MM-dd");
      const to_date = this.datePipe.transform(this.todate, "yyyy-MM-dd");

      if (from_date) {
        this.payload.push({
          fromDate: from_date,
        });

        this.tableData.push({
          id: this.payload.length - 1,
          category: "From Date",
          value: from_date,
        });
        this.fromdate = "";
      }

      if (to_date) {
        this.payload.push({
          toDate: to_date,
        });

        this.tableData.push({
          id: this.payload.length - 1,
          category: "To Date",
          value: to_date,
        });
        this.todate = "";
      }
    }

    if (this.validateInput() && this.selectedItems.length > 0) {
      if (this.selectedItems[0].id == "1") {
        this.payload.push({ direction: "1" });
      } else if (this.selectedItems[0].id == "2") {
        this.payload.push({ direction: "2" });
      } else if (this.selectedItems[0].id == "3") {
        this.payload.push({ callback: "5" });
      } else if (this.selectedItems[0].id == "4") {
        this.payload.push({ did: this.inputValue });
      } else if (this.selectedItems[0].id == "5") {
        this.payload.push({ customer_no: this.inputValue });
      } else if (this.selectedItems[0].id == "6") {
        this.payload.push({ agent_no: this.inputValue });
      } else if (this.selectedItems[0].id == "7") {
        this.payload.push({ agent_name: this.inputValue });
      } else if (this.selectedItems[0].id == "8") {
        this.payload.push({ dep_name: this.inputValue });
      } else if (this.selectedItems[0].id == "9") {
        this.payload.push({ tduration_gt: this.inputValue });
      } else if (this.selectedItems[0].id == "10") {
        this.payload.push({ tduration_lt: this.inputValue });
      } else if (this.selectedItems[0].id == "11") {
        this.payload.push({ cduration_gt: this.inputValue });
      } else if (this.selectedItems[0].id == "12") {
        this.payload.push({ cduration_lt: this.inputValue });
      } else if (this.selectedItems[0].id == "13") {
        this.payload.push({ DTMF: this.inputValue });
      } else if (this.selectedItems[0].id == "14") {
        this.payload.push({ status: "ANSWERED" });
      } else if (this.selectedItems[0].id == "15") {
        this.payload.push({ status: "NOANSWER" });
      } else if (this.selectedItems[0].id == "16") {
        this.payload.push({ status: "BUSY" });
      } else if (this.selectedItems[0].id == "17") {
        this.payload.push({ status: "UNAVAILABLE" });
      } else if (this.selectedItems[0].id == "18") {
        this.payload.push({ status: "CANCEL" });
      } else if (this.selectedItems[0].id == "19") {
        this.payload.push({ status: "NO INPUT" });
      } else if (this.selectedItems[0].id == "20") {
        this.payload.push({ status: "NO_CHANNEL" });
      }

      this.tableData.push({
        id: this.payload.length - 1,
        category: this.selectedItems[0].itemName,
        value: this.inputValue,
      });

      this.selectedItems = [];
      this.inputValue = "";
      this.isInputDisabled = false;
    }
    this.pagination.current_page = 1;
    this.generateReportTable();
  }

  deleteRow(row) {
    const index = this.tableData.indexOf(row);
    this.tableData.splice(index, 1);
    this.payload.splice(row.id, 1);
    this.ElasticReportData.length = 0;
    this.tableData.forEach((e) => {
      if (e.id > row.id) {
        e.id -= 1;
      }
    });

    if (this.tableData.length < 1) {
      this.pagination.current_page = 0;
      this.pagination.total_page = 0;
    } else {
      this.pagination.current_page = 1;
      this.generateReportTable();
    }
  }
  //Start Input  Methods

  validateInput() {
    if (this.selectedItems.length != 0) {
      if (
        [4, 5, 6, 7, 8, 9, 10, 11, 12, 13].includes(this.selectedItems[0].id)
      ) {
        let isvalid = this.inputValue == "" ? false : true;
        if ([4, 5, 6, 9, 10, 11, 12, 13].includes(this.selectedItems[0].id)) {
          isvalid = /^\d+$/.test(this.inputValue);
        }
        this.isInputValid = isvalid;
        return isvalid;
      } else {
        this.isInputValid = true;
        return true;
      }
    } else if (this.fromdate || this.todate) {
      this.isInputValid = true;
      return true;
    }
  }

  //Generate Report Table

  generateReportTable() {
    if (this.payload.length > 0) {
      if (this.pagination.current_page == 0) {
        this.pagination.current_page = 1;
      }
      const data: Data = {
        payload: this.payload,
        pagination: this.pagination,
        user: this.user_data,
      };
      this.spinnerService.show();
      this.http
        .post(API_URL+ "elasticreport/", data)
        .subscribe((response: any) => {
          if (response.data.length > 0) {
            this.ElasticReportData = response.data;
            this.pagination.current_page = response.pagination.current_page;
            this.pagination.total_page = response.pagination.total_page;
            this.spinnerService.hide();
          } else {
            this.spinnerService.hide();
            this.notifyService.sendNotification(
              "Alert",
              "No data found. Please modify filter.",
              "is-danger"
            );
          }
        });
    }
  }

  // Report Pagination

  paginate(event) {
    if (event == "first") {
      this.pagination.current_page = 1;
      this.generateReportTable();
    }

    if (event == "last") {
      this.pagination.current_page = this.pagination.total_page;

      this.generateReportTable();
    }

    if (
      event == "next" &&
      this.pagination.current_page < this.pagination.total_page
    ) {
      this.pagination.current_page += 1;
      this.generateReportTable();
    }

    if (event == "prev" && this.pagination.current_page != 1) {
      this.pagination.current_page -= 1;
      this.generateReportTable();
    }
  }

  fetchNumber(num) {
    if (parseInt(num)=== parseInt(num, 10) && num > 0 && num <= this.pagination.total_page) {
      this.pagination.current_page = parseInt(num);
      this.generateReportTable();
    }
    else{
      Swal.fire('Oops..!','Enter Valid Number', 'warning');
    }
    
  }

  getcallrecord(vffile, date) {
    var from = this.datePipe.transform(this.fromdate, "yyyy-MM-dd");
    var to = this.datePipe.transform(this.todate, "yyyy-MM-dd");
    this.spinnerService.show();
    this.reportservice.getcallrecord(vffile, date).subscribe((res: any) => {
      this.reportservice
        .getElasticReport(this.payload, this.pagination, this.user_data, "callrecord")
        .subscribe((res: any) => {
          this.ElasticReportData = res["data"];
          this.pagination.total_page = res["pagination"]["total_page"];
          this.pagination.current_page = res["pagination"]["current_page"];
          this.spinnerService.hide()
          this.notifyService.sendNotification(
            "Downloaded",
            "Download Voice successfully",
            "is-success"
          );
        });
    });
  }

  exportAsXLSX(): void {
    if (this.ElasticReportData.length > 0) {
      let text;
      if (this.fromdate != "" && this.todate != ""){
        var from = this.datePipe.transform(this.tableData.filter((item)=>item['category'] === "From Date")[0].value, "yyyy-MM-dd");
        var to = this.datePipe.transform(this.tableData.filter((item)=>item['category'] === "To Date")[0].value, "yyyy-MM-dd");
      }

      this.spinnerService.show();
      this.reportservice
        .getElasticReport(this.payload, this.pagination, this.user_data, "yes")
        .subscribe((res: any) => {
          this.ExcelPdfReportData = res["data"];
          for (let element of this.ExcelPdfReportData) {
            // if (element["destinationType"] == "6") {
            //   var excel_destination_name = "AUDIO";
            // } else {
            //   excel_destination_name = element["queueName"];
            // }

            var objz = {
              "Sl No": element["slno"],
              "Date & Time": this.getFormattedDateTime(
                element["legAstartTime"]
              ),
              Source: element["source"] +" "+ this.getAgentNames(element["source"]).join(', '),
              Destination: element["destination"] +" "+ this.getAgentNames(element["destination"]).join(', '),
              Direction: element["direction"],
              DID: element["DID"],
              "Department Name": element["departmentName"],
              Status: element["status"],
              "Call Duration": element["callduration"],
              "Total Duration": element["totalduration"],
              'Comment':element['comment']
            };

            this.wordarray.push(objz);
          }
          if (from != null && to != null) {
            text = "Elastic Report from: " + from + " To: " + to + "";
          } else {
            text = "Elastic Report of " + this.today_informat;
          }
          
          this.excelService.exportAsExcelFile(this.wordarray, text);
          this.wordarray = [];
          this.spinnerService.hide();
        });
    }
  }

  downloads() {
    if (this.ElasticReportData.length > 0) {
      if (this.fromdate != "" && this.todate != ""){
        var from = this.datePipe.transform(this.tableData.filter((item)=>item['category'] === "From Date")[0].value, "yyyy-MM-dd");
        var to = this.datePipe.transform(this.tableData.filter((item)=>item['category'] === "To Date")[0].value, "yyyy-MM-dd");
      }
      var countSlNo = 1;
      var newarray = [];
      var head = [
        [
          "Sl No",
          "Date & Time",
          "Source",
          "Destination",
          "Direction",
          "DID",
          "Department Name",
          "Status",
          "Call Duration",
          "Total Duration",
          "Comment"
        ],
      ];

      
      this.spinnerService.show();
      this.reportservice
        .getElasticReport(this.payload, this.pagination, this.user_data, "yes")
        .subscribe((res: any) => {
          this.ExcelPdfReportData = res["data"];
          for (let element of this.ExcelPdfReportData) {
            newarray.push([
              element["slno"],
              this.getFormattedDateTime(element["legAstartTime"]),
              element["source"] +" "+ this.getAgentNames(element["source"]).join(', '),
              element["destination"] +" "+ this.getAgentNames(element["destination"]).join(', '),
              element["direction"],
              element["DID"],
              element["departmentName"],
              element["status"],
              element["callduration"],
              element["totalduration"],
              element["comment"]
            ]);
          }
          var pdf = new jsPDF();
          pdf.text("Elastic Report", 11, 8);
          pdf.setFontSize(3);
          pdf.setTextColor(100);
          (pdf as any).autoTable({
            head: head,
            body: newarray,
            theme: "grid",
            styles: { fontSize: 4 },
            tableWidth: "auto",
            columnStyles: { 0: { halign: "center", fillColor: [0, 255, 0] } }, // Cells in first column centered and green
            margin: { top: 10 },
          });
          if(from != null && to !=null){
            pdf.save("Elastic Report" +" "+ from + "_to_" + to + ".pdf"); 
          }
          else{
            pdf.save("Elastic Report " +this.today_informat+ ".pdf"); 
          }
                
          this.spinnerService.hide();
        });
    }
  }

  DownloadallVoice() {
    if (this.ElasticReportData.length > 0) {
      if (this.fromdate != "" && this.todate != ""){
        var from = this.datePipe.transform(this.fromdate, "yyyy-MM-dd");
        var to = this.datePipe.transform(this.todate, "yyyy-MM-dd");
      }
      this.reportservice
        .getElasticReport(
          this.payload,
          this.pagination,
          this.user_data,
          "download_all_voice"
        )
        .subscribe((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              this.isShownprogressbar = true;
              console.log("Request has been made!");
              break;
            case HttpEventType.ResponseHeader:
              console.log("Response header has been received!");
              break;
            case HttpEventType.DownloadProgress:
              this.progress = Math.round((event.loaded / event.total) * 100);
              console.log(`Uploaded! ${this.progress}%`);
              break;
            case HttpEventType.Response:
              console.log("User successfully created!", event.body);
              var a = document.createElement("a");
              var blob = new Blob([event.body], { type: "application/zip" });
              a.href = URL.createObjectURL(blob);
              if (from && to) {
                a.download = from + "_" + to + ".zip";
              } else {
                a.download = "CALLRECORD_" + this.today_informat + ".zip";
              }
              a.click();
              setTimeout(() => {
                this.progress = 0;
                this.isShownprogressbar = false;
              }, 1500);
          }
        });
    }
  }

  onDownload(): void {
    this.id = 1;
    let filename = null;
    this.reportservice.download().subscribe((event) => {
      console.log(event["loaded"]);
      console.log(event["total"]);

      if (event["loaded"] && event["total"]) {
        this.value = Math.round((event["loaded"] / event["total"]) * 100);
        console.log(this.value);

        // if (this.value==100){
        //   // this.id=0;
        // }
      }

      if (event["body"]) {
        saveAs(event["body"], filename);
      }
    });
    // this.id=0;
  }

  getAgentNames(mobile: string) {
    if (mobile) {
      return this.agentList.filter((agent)=> agent.agent_number.slice(-10) === mobile.slice(-10)).map((agent) => agent.agent_name);
    } else {
      return [];
    }
  }

  getFormattedDateTime(datetime): string {
    const formattedDateTime = new Date(datetime).toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    const [date, time] = formattedDateTime.split(", ");
    const formattedDate = date.replace(/\//g, "-");
    return `${formattedDate}, ${time}`;
  }


  CloseComment(){
    console.log("=============TRYING TO CLOSE 2====")
    this.commentopen = false
    this.call_id=""
    this.comment =""
  }
  

  
}
