import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { LoginService } from '../login.service';
import { ActivatedRoute } from '@angular/router';
import { VoiceRecordService } from './voice-record.service';
import { NotificationService } from '../notification/notification.service';
import Swal from 'sweetalert2';
import { FileuploadService } from '../fileupload/fileupload.service';
import { Input, Output, EventEmitter } from '@angular/core';
import { API_URL } from 'src/global';

@Component({
  selector: 'app-voice-record',
  templateUrl: './voice-record.component.html',
  styleUrls: ['./voice-record.component.css']
})
export class VoiceRecordComponent implements OnInit {
  fileName: string;
  api:any=API_URL
  id:number = 0
  VoiceForm:FormGroup;
  voiceFileLabel:string = 'Voice File';
  voiceFileData:string = '';
  userData:any;
  voiceRecordData:any = new Array();
  broadCastData:boolean = false;
  playPuase:number = 0;
  isAdmin:string = 'false';
  voiceData:string = 'false';
  btnDisabled:boolean=false;
  @Input() musicUpload: string;
  @Input() audioLabel: string
  @Output() filePathEmitter = new EventEmitter();
  public fileToUpload:File = null;
  addBtn: string = 'false';

  VoiceRecordType:any;
  options = [
    { name: 'VoiceFile Upload', value: 1 },
    { name: 'Text To Speech', value: 2 }
   
  ];
  selectedOption = this.options[0].value; // Set default value to first option
  languages=[
    { name: 'Select Language', value: '' },
    { name: 'English', value: 'en-IN' },
    { name: 'Hindi', value: 'hi-IN' },
    { name: 'Malayalam', value: 'ml-IN' },

   
  ];
  selectedLanguages = this.languages[0].value; // Set default value to first option

  constructor(
    private loginservice: LoginService,
    private voicerecordservice: VoiceRecordService,
    private notifyservice: NotificationService,
    private fileupload: FileuploadService,
    

  ) {this.fileName = '';  }

  ngOnInit() {
    this.userData = this.loginservice.getUserdata(true);
    this.addBtn = 'false';
    this.isAdmin = localStorage.getItem('isAdmin')
    this.VoiceForm = new FormGroup({
      name: new FormControl('', Validators.required),
      textSpeech:new FormControl(''),
      textPitch:new FormControl('0'),
      speakingRate:new FormControl('0.81'),
      languageField:new FormControl(this.selectedLanguages),
      fileUrl: new FormControl(''),
      broadCast: new FormControl(false),
      user: new FormControl(this.userData.id),
      adminPermission: new FormControl(true)
    });
    if(this.userData.group == 'superadmin'){
      this.voicerecordservice.getVoiceRecordList(0,0).subscribe(
        (res:any) => {
          this.voiceRecordData = res
        }
      )
    }else{
      this.voicerecordservice.getVoiceRecordList(this.userData.id,0).subscribe(
        (res:any) => {
          this.voiceRecordData = res
        }
      )
    }
  }




  
  // voiceFilePath(data) {
  //   debugger;
  //   console.log("fffffffffffffff",data)
  //   if(data.url == '0'){
  //     Swal.fire('Oops ...', 'Please Select .wav file', 'warning');
  //   }else{
  //     this.btnDisabled=true;
  //     this.voiceData = 'false'
  //     this.voiceFileData = data.url
  //     if(this.voiceFileData!=null){
  //       this.voiceData = 'true';
  //     }
  //   }
  // }
  handleFileInput(files: FileList) {
    
    
    this.fileToUpload = files.item(0);
    console.log("fileUpload======",this.fileToUpload)
    if (this.fileToUpload != null) {
			console.log('file is=========== ' + this.fileToUpload);
			this.fileupload.uploadfiles(this.fileToUpload, this.userData.id).subscribe(
        (data:any) =>{
          console.log("dataaaa",data)
          // this.filePathEmitter.emit(data);
          this.voiceFileData = data.url
          this.addBtn = 'true';
          
          this.fileName = this.fileToUpload.name;
        }
      )
		}
  }

  // upload() {
	// 	if (this.fileToUpload != null) {
	// 		console.log('file is ' + this.fileToUpload);
	// 		this.fileupload.uploadfiles(this.fileToUpload, this.userData.id).subscribe(
  //       (data:any) =>{
  //         this.filePathEmitter.emit(data);
  //       }
  //     )
	// 	}
	// }

  get f() { return this.VoiceForm.controls; }
  
  onSubmit() {
    console.log(this.VoiceForm.value)
    if (this.VoiceForm.invalid) {
      return;
    }else{
      this.VoiceForm.value.adminPermission = !this.VoiceForm.value.broadCast
      this.VoiceForm.value.fileUrl =  this.voiceFileData
      console.log("VoiceFormValues======="+this.VoiceForm.value)
      this.voicerecordservice.addVoiceRecord(this.VoiceForm.value).subscribe(
        (res:any) => {
          if(res["status"] == "1"){
            this.notifyservice.sendNotification('Created', 'Voice record created is successfully', 'is-success')
            if(this.userData.group == 'superadmin'){
              this.voicerecordservice.getVoiceRecordList(0,0).subscribe(
                (res:any) => {
                  this.voiceRecordData = res
                }
              )
            }else{
              this.voicerecordservice.getVoiceRecordList(this.userData.id,0).subscribe(
                (res:any) => {
                  this.voiceRecordData = res
                }
              )
            }
          

            this.VoiceForm.setValue({
              name : '',
              textSpeech:'',
              speakingRate:0.81,
              textPitch:0,
              fileUrl: '',
              broadCast: false,
              user: this.userData.id,
              adminPermission: true,
              languageField:''
            });
           
            this.id = 0;
            this.voiceFileLabel = 'Voice File';
            
          }
          if(res["status"] == "0"){
            this.notifyservice.sendNotification('Failed', 'An audio file with the same file name already exists.', 'is-danger')
          }
        }
      )
    }
  }

  onUpdate(id) {
    if(this.isAdmin == 'true'){
      if (this.VoiceForm.invalid) {
        return;
      }else {
        this.VoiceForm.value.fileUrl =  this.voiceFileData
        this.voicerecordservice.updateVoiceRecord(id, this.VoiceForm.value).subscribe(
          (res:any) => {
            if(res && res.id){
              this.notifyservice.sendNotification('Updated', 'Voice record updated is successfully', 'is-success')
              if(this.userData.group == 'superadmin'){
                this.voicerecordservice.getVoiceRecordList(0,0).subscribe(
                  (res:any) => {
                    this.voiceRecordData = res
                  }
                )
              }else{
                this.voicerecordservice.getVoiceRecordList(this.userData.id,0).subscribe(
                  (res:any) => {
                    this.voiceRecordData = res
                  }
                )
              }
              this.VoiceForm.setValue({
                name : '',
                fileUrl: '',
                broadCast: false,
                user: this.userData.id,
                adminPermission: true,
                languageField:''
              });
              this.id = 0;
              this.voiceFileLabel = 'Voice File';
            }
          }
        )
      }
    }else{
      if (this.VoiceForm.invalid) {
        return;
      }else {
        this.VoiceForm.value.fileUrl =  this.voiceFileData
        this.VoiceForm.value.adminPermission = !this.VoiceForm.value.broadCast
        this.voicerecordservice.updateVoiceRecord(id, this.VoiceForm.value).subscribe(
          (res:any) => {
            if(res && res.id){
              this.notifyservice.sendNotification('Updated', 'Voice record updated is successfully', 'is-success')
              if(this.userData.group == 'superadmin'){
                this.voicerecordservice.getVoiceRecordList(0,0).subscribe(
                  (res:any) => {
                    this.voiceRecordData = res
                  }
                )
              }else{
                this.voicerecordservice.getVoiceRecordList(this.userData.id,0).subscribe(
                  (res:any) => {
                    this.voiceRecordData = res
                  }
                )
              }
              this.VoiceForm.setValue({
                name : '',
                fileUrl: '',
                broadCast: false,
                user: this.userData.id,
                adminPermission: true
              });
              this.id = 0;
              this.voiceFileLabel = 'Voice File';
            }
          }
        )
      }
    }
    
  }

  onEdit(id) {
    this.id = id
    this.voicerecordservice.getVoiceRecord(this.id).subscribe(
      (res:any) => {
        if(res && res.id) {
          this.id = res.id
          this.voiceFileData = res.fileUrl
          this.voiceFileLabel = res.fileUrl
          this.VoiceForm.setValue({
            name : res.name,
            fileUrl: res.fileUrl,
            broadCast: res.broadCast,
            user: res.user,
            adminPermission: res.adminPermission
          })
        }
      }
    )
  }

  onDelete(id) {
    Swal.fire({
      title: 'Are you sure ?',
      text: 'Voice Record Deleted',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'No, Cancel it!'
    }).then((result) => {
      if (result.value) {
        this.voicerecordservice.deleteVoiceRecord(id).subscribe(
          (res:any) => {
            console.log('data', res)
            this.notifyservice.sendNotification('Deleted','Voice record delete successfully','is-success')
            if(this.userData.group == 'superadmin'){
              this.voicerecordservice.getVoiceRecordList(0,0).subscribe(
                (res:any) => {
                  this.voiceRecordData = res
                }
              )
            }else{
              this.voicerecordservice.getVoiceRecordList(this.userData.id,0).subscribe(
                (res:any) => {
                  this.voiceRecordData = res
                }
              )
            }
          }
        )
      }else{
        this.notifyservice.sendNotification('Cancelled','Voice record deleting has been cancelled','is-danger') 
      }
    })
  }

  onVoiceRecordType(){
    // alert(this.selectedOption)
  }





}

