import { Component, OnInit, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { WhatsappService } from '../whatsapp-services';
import { LoginService } from 'src/app/login.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-whatsapp-configuration',
  templateUrl: './whatsapp-configuration.component.html',
  styleUrls: ['./whatsapp-configuration.component.css']
})
export class WhatsappConfigurationComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() customerEmitter: any = new EventEmitter();
  customerInitial: string | null = null;
  customerId: string = "";
  customerList: any[] = [];
  apiKey: string = "";
  whatsappUsersList: any[] = [];
  dataTable: any;
  userData: any;
  userListReadOnly: boolean = false;
  whatsappForm: FormGroup;
  isSubmitted: boolean = false;
  userId: string | null = null;
  clearTrigger: boolean = false;
  directionOptions = [
    { value: null, name: "Select direction" },
    { value: "1", name: "Incoming" },
    { value: "2", name: "Outgoing" },
    { value: "3", name: "Both" },
  ]

  targetOptions = [
    { value: null, name: "Select target" },
    { value: "1", name: "Agents" },
    { value: "2", name: "Customers" },
    { value: "3", name: "Both" },
  ]

  constructor(
    private whatsappService: WhatsappService,
    private loginService: LoginService,
    private fb: FormBuilder
  ) {
    this.whatsappForm = this.fb.group({
      apiKey: ["", Validators.required],
      direction: [null, Validators.required],
      target: [null, Validators.required]
      })
   }

  ngOnInit() {

    this.userData = this.loginService.getUserdata(true);

  }

  ngAfterViewInit(): void {
    this.initializeDatatable();
    this.updateDataTable();
    this.intializeUserSettings();
  }

  ngOnDestroy(): void {
    this.dataTable.destroy();
  }

  
  customerEvent(userId) {
    this.customerId = userId;
    // this.customerInitial = this.whatsappUsersList.find(item => item.id == userId).username;
  }

  initializeDatatable() {
    this.dataTable = $('#userTable').DataTable({
      responsive: true,
      lengthChange: false,
      autoWidth: false,
      language: {
        emptyTable: 'No data available'
      },
      buttons: [],
      columns: [
        { data: 'sl_no', title: 'Sl No' }, 
        { data: 'username', title: 'User Name' },
        { data: 'api_key', title: 'API Key' },
        { data: 'direction_name', title: 'Direction' },
        { data: 'target_name', title: 'Reciepient' },
        { data: 'action', title: 'Action' },
        // Add more columns as needed
      ]
    });
  }

  updateDataTable() {

    if (this.userData.group == 'superadmin') {
      const qParams = [
        {key:"scope", value :"superadmin"}
      ]
      this.whatsappService.getWhatsappUsersList(qParams).subscribe(
        (res: any) => {
          if (res.status === "1") {
            this.whatsappUsersList = res.data.map((item, index) => ({
              ...item,
              sl_no: index + 1,
              action: `<a id="btn-edit"><span class="icon has-text-dark"><i class="fa fa-edit" aria-hidden="true"></i></span></a>
                     <a id="btn-delete"><span class="icon has-text-dark"><i class="fa fa-trash-o"></i></span></a>`
            }));
            this.dataTable.clear();
            this.dataTable.rows.add(this.whatsappUsersList);
            this.dataTable.draw();
          }
        }
      )
    } else {
      const qParams = [
        { key: "scope", value: "customer" },
        { key: "userprofile_id", value: this.userData.id}
      ]
      this.whatsappService.getWhatsappUsersList(qParams).subscribe(
        (res: any) => {
          if (res.status === "1") {
            this.whatsappUsersList = res.data.map((item, index) => ({
              ...item,
              sl_no: index + 1,
              action: `<a id="btn-edit"><span class="icon has-text-dark"><i class="fa fa-edit" aria-hidden="true"></i></span></a>
                    <a id="btn-delete"><span class="icon has-text-dark"><i class="fa fa-trash-o"></i></span></a>`
            }));
            this.dataTable.clear();
            this.dataTable.rows.add(this.whatsappUsersList);
            this.dataTable.draw();
          }
        }
      )
    }

    $('#userTable tbody').on('click', '#btn-delete', (event: any) => {

      const row = this.dataTable.row($(event.target).closest('tr')).data();
      if (row) {
        this.deleteWhatsappUser(row.id);
      }
    });

    $('#userTable tbody').on('click', '#btn-edit', (event: any) => {

      const row = this.dataTable.row($(event.target).closest('tr')).data();
      if (row) {
        this.editWhatsappUser(row);
      }
    });
  }

  deleteWhatsappUser(id: string) {
    Swal.fire({
      title: "Are you sure ?",
      text: "The user and related data will be delete.",
      showCancelButton: true,
      confirmButtonText: "Yes, Delete User.",
      cancelButtonText: "No, Dont delete.",
    }).then((resp) => {
      if (!resp.dismiss) {
        this.whatsappService.deleteWhatsappUser(id).subscribe((res: any) => {
          if (res["status"] === "1") {
            Swal.fire("Success", res["message"], "success")
            this.updateDataTable();
          }
        })
      }
    })
  }

  editWhatsappUser(row) {
    this.userId = row.id;
    this.customerInitial = row.username;
    this.customerId = row.user;
    this.whatsappForm.patchValue({
      apiKey: row.api_key,
      direction: row.direction,
      target: row.target
    })
    
  }

  intializeUserSettings() {
    if (this.userData.usergroup != "superadmin") {
      this.userListReadOnly = true;      
      this.customerInitial = this.userData.name;
      this.customerId = this.userData.id;
    }
  }

  onSubmit() {
    this.markFormGroupAsTouched(this.whatsappForm);
    this.isSubmitted = true;
    if (this.whatsappForm.valid) {
      if (this.customerId) {
        if (this.userId) {
          const data = {
            user_id: this.userId,
            user: this.customerId,
            api_key: this.whatsappForm.value.apiKey,
            direction: this.whatsappForm.value.direction,
            target: this.whatsappForm.value.target
          }
          this.whatsappService.updateWhatsappUser(data).subscribe((res: any) => {
            if (res.status === "1") {
                    
              this.isSubmitted = false;
              this.whatsappForm.reset();
              this.updateDataTable();
              if (this.userData.usergroup == "superadmin") {
                this.customerInitial = null;    
                this.customerId = null;
              }
              Swal.fire("Success", "User updated successfully.", "success");
            } else {
              Swal.fire("Something went wrong", "User updation Failed.", "error");
            }
          })
        } else {
          const data = {
            user_id: this.customerId,
            api_key: this.whatsappForm.value.apiKey,
            direction: this.whatsappForm.value.direction,
            target: this.whatsappForm.value.target
          }
          this.whatsappService.addWhatsappUser(data).subscribe((res: any) => {
            if (res.status === "1") {
              this.clearTrigger = true;              
              this.isSubmitted = false;
              this.whatsappForm.reset();
              this.updateDataTable();
              if (this.userData.usergroup == "superadmin") {
                this.customerInitial = null;    
                this.customerId = null;
              }
              Swal.fire("Success", "User added successfully.", "success");
            } else {
              Swal.fire("Something went wrong", "User creation failed.", "error");
            }
          })
        }

      }  
    }
    
  }

  markFormGroupAsTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(controlName => {
      const control = formGroup.get(controlName);

      if (control instanceof FormGroup) {
        this.markFormGroupAsTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }




}
