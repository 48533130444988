import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LoginService } from 'src/app/login.service';
import { ReportSettingsService } from '../report-settings.service';

@Component({
  selector: 'app-child-tag-icon',
  templateUrl: './child-tag-icon.component.html',
  styleUrls: ['./child-tag-icon.component.css']
})
export class ChildTagIconComponent implements OnInit {

  @Input() public call_record_id: any
  @Output() callRecordUpdated = new EventEmitter<number>();

  showTagDiv: boolean = false;
  userData: any
  tagArray: any

  constructor(
    private loginservice: LoginService,
    private reportsettingservice: ReportSettingsService
  ) { }

  ngOnInit() {
    this.userData = this.loginservice.getUserdata(true);
  }

  onTagIconClick() {
    this.showTagDiv = !this.showTagDiv;

    if(this.showTagDiv){
      this.getMainTags()
    }
  }

  getMainTags(){
    let user_id:any
    if(this.userData.usertype == 'agentUser' || this.userData.usertype == 'dialerUser') {
      user_id = this.userData.admin;
    } else {
      user_id = this.userData.id;
    }

    this.reportsettingservice.getReportTagMain(user_id).subscribe((res:any) => {
      this.tagArray = res.report_tags
    })
  }

  selectTag(tag:any){
    const body = {
      call_record_id: this.call_record_id,
      tag: tag
    }

    this.reportsettingservice.addReportTagChild(this.call_record_id, body).subscribe((res:any) => {
      this.showTagDiv = false
      this.callRecordUpdated.emit(this.call_record_id)
    })
  }

}
