import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/login.service';
import { ExtensionService } from 'src/app/extension/extension.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list-virtualsip',
  templateUrl: './list-virtualsip.component.html',
  styleUrls: ['./list-virtualsip.component.css']
})
export class ListVIrtualsipComponent implements OnInit {
  isAdmin: string;
  userData: any;
  permissions: any;
  VirtualSipData: any;

  constructor(
    private loginService:LoginService,
    private extensionService:ExtensionService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.isAdmin = localStorage.getItem('isAdmin')
    this.userData = this.loginService.getUserdata(false);
    this.permissions = this.userData.permissions
    
   
    this.extensionService.getVirtualSipList(this.userData.id).subscribe(
      (data:any)=>{
        this.VirtualSipData=data["data"];
        console.log('data',this.VirtualSipData);
      }
    )
  }


  Edit(id){
		this.router.navigateByUrl('/virtualSipDetails/'+id);
		
  }
  
  Delete(id) {
    Swal.fire({
      title: 'Are you sure ?',
      text: 'The Sip will be  Deleted',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'No, Cancel it!'
  }).then((result) => {
    if (result.value) {
      this.extensionService.deleteSip(id).subscribe(
        (res:any) => {
          console.log('data', res)
          Swal.fire(
            'Deleted',
            'Virtual Sip Deleted Successfully',
            'success'
          )
          this.extensionService.getVirtualSipList(this.userData.id).subscribe(
            (data:any)=>{
              this.VirtualSipData=data["data"];
              console.log('data',this.VirtualSipData);
            }
          )
        }
      )
    }else{
      Swal.fire(
        'Cancelled',
        'Virtual Sip Deletion has been Cancelled',
        'error'
      )
      
    }
  })
}

}
