import { Component, OnInit ,Input, EventEmitter,Output} from '@angular/core';
import { ReportService } from "../../inbound-report/report.service";
import { NotificationService } from "src/app/notification/notification.service";
import { API_URL } from 'src/global';

@Component({
  selector: 'app-audiocomp',
  templateUrl: './audiocomp.component.html',
  styleUrls: ['./audiocomp.component.css']
})
export class AudiocompComponent implements OnInit {

  @Input() public id:any = null;
  @Input() public date:any;
  @Input() public callrecord:any;
  @Input() public call_type:any='';


  api: string = API_URL;

  constructor(
    private reportservice: ReportService,
    private notifyService: NotificationService

  ) { }


  ngOnInit() {
  }

  getcallrecord(vffile, date, call_id: any = null, file_type = "") {
 
    this.reportservice.getcallrecord(vffile, date,call_id, file_type).subscribe(
      (res: any) => {

          let voice_url = this.api + "uploads/downloadedWaveFile/" + vffile.replace(".gsm", ".wav");
          var html_con = '<audio id="audio1" preload controls style="width: 270px; height: 50px; max-width: none; margin: 10px;">' + "  <source src=" + voice_url + " </audio>";
          document.getElementById("audio_" + vffile).innerHTML = html_con;

        this.notifyService.sendNotification(
          "Downloaded",
          "Download Voice successfully",
          "is-success"
        )
      }
    )
  }

  isAudioFileAvailable(url: string){
    console.log("url of audio file: ", url)  
  }

  audioFileErrors: boolean[] = []
  downloadEnabled: boolean[] = []

  handleAudioError(audioFileName: string) {
    this.audioFileErrors[audioFileName] = true
    this.downloadEnabled[audioFileName] = true
  }

  disableVoiceDownload(audioFileName: string){
    this.downloadEnabled[audioFileName] = false
  }

}
