export const environment = {

  firebaseConfig: {
    apiKey: "AIzaSyDPGB1450_LRUcQWMBDDoSQaXnRRpGrsms",
    authDomain: "bonvoice-solutions.firebaseapp.com",
    databaseURL: "https://bonvoice-solutions-default-rtdb.firebaseio.com",
    projectId: "bonvoice-solutions",
    storageBucket: "bonvoice-solutions.appspot.com",
    messagingSenderId: "473385320230",
    appId: "1:473385320230:web:10bc66b76d7097df834be7",
    measurementId: "G-XEV62PDVL4"
  },
  production: true,
  title: 'Production Environment Heading',
  apiURL: '//backend.pbx.bonvoice.com/',
  voiceURL: '//backend.pbx.bonvoice.com/'
};
