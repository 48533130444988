import { Injectable } from '@angular/core';
import{
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpClient,
  HttpHeaders
} from '@angular/common/http'
import { Observable } from 'rxjs';
import { LoginService } from './login.service';


@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

  constructor(
    private http:HttpClient,
    private LoginService:LoginService
  ){}



  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Get the authentication token
    const authToken = this.LoginService.getToken()
   
    // Clone the request and add the authorization header if the token exists
    if (request.url.includes('get_auth_token/')){      
      
      return next.handle(request);
    }

    if (request.url.includes('getServer/')){
      return next.handle(request);
    }

    if (request.url.includes('login/')){
      return next.handle(request);
    }

    if (authToken) {

      const authReq = request.clone({
        setHeaders: {
          Authorization: `Token ${authToken}`,
        },
      });
      return next.handle(authReq);
    }

    // If no token, pass the original request
    return next.handle(request);
  }

}