import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/login.service';
import { UsermanagementService } from "../../app/usermanagement.service";
import { DatePipe } from '@angular/common';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ReportService } from '../../app/inbound-report/report.service';
import { NotificationService } from 'src/app/notification/notification.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { ExcelService } from '../inbound-report/excel.service';
import * as jsPDF from "jspdf";


@Component({
  selector: 'app-autocall-report',
  templateUrl: './autocall-report.component.html',
  styleUrls: ['./autocall-report.component.css'],
  providers: [DatePipe]

})


export class AutocallReportComponent implements OnInit {

  datas: any;
  voiceRecordData:any = new Array();
  userData:any;
  searchData:string='';
  callstatus:string;
  date1:string;
  goPageNumber:string;
  current_page:any;
  last_page:any;
  fromdate:any;
  todate:any;
  today = new Date();
  AutoReportData: any;
  myDate = new Date();
  tomorrow =  new Date(this.today.setDate(this.today.getDate() + 1));
  tdy =  new Date(this.myDate.setDate(this.myDate.getDate()));
  reportForm:FormGroup;
  userType:any;
  ExcelPdfReportData:any = new Array();
  wordarray:any = new Array();


   // Settingsforcomment
   commentopen:boolean=false;
   comment:any
   call_id:any;
   


  constructor(   
     private reportservice: ReportService,
     private loginservice: LoginService,
     private usermanagementservice:UsermanagementService,
     private datePipe: DatePipe,
     private notifyService: NotificationService,
     private excelService: ExcelService
    ) { 
    
  }


  ngOnInit() {
    this.fetchData(1)
    this.userType=localStorage.getItem('userType')
    console.log("AutoCallReport----Usertype----",this.userType)
    this.reportForm = new FormGroup({
      toDate: new FormControl(''),
      fromDate: new FormControl(''),
      user: new FormControl(0),
      searchText : new FormControl(''),
      callStatus : new FormControl(''),
    });
  }



  fetchData(pageNumberData){

    this.userData = this.loginservice.getUserdata(true);
    var from;
    var to;
    if(this.date1 == "Invalid date" || this.date1 == undefined){
      from = this.datePipe.transform(this.tdy, 'yyyy-MM-dd');
      to = this.datePipe.transform(this.tomorrow, 'yyyy-MM-dd');
      this.userType=localStorage.getItem('userType')
    }else{
      this.fromdate = moment(this.reportForm.value.fromDate, "YYYY-MM-DD").format("YYYY-MM-DD")
      this.todate = moment(this.reportForm.value.toDate, "YYYY-MM-DD").format("YYYY-MM-DD")
      from = this.fromdate
      to = this.todate
    }

    var body={"userId":this.userData.id,"userType":this.userType,"from":from,"to":to,"search":this.searchData,"callstatus":this.callstatus}
    this.usermanagementservice.load_auto_call_report_by_page(body,pageNumberData).subscribe(
      (res:any) => {
        this.current_page = res.current_page;
        this.datas = res.results;
        this.last_page=res.total_pages; 
        console.log(res);
      }
    )
  }

  fetchByPageNumber(pagenumber){
    if (parseInt(pagenumber)=== parseInt(pagenumber, 10) && pagenumber > 0 && pagenumber <= this.last_page) {
      this.current_page = parseInt(pagenumber);
      this.fetchData(pagenumber);
    }
    else{
      Swal.fire('Oops..!','Enter Valid Number', 'warning');
    }
    // this.fetchData(pagenumber)
  }

  fetchPreviousPage(){
    this.fetchData(this.current_page - 1)

  }

  fetchFirstPage(){
    this.fetchData(1)

  }

  fetchNextPage(){
    this.fetchData(this.current_page + 1)

  }

  getLastPage(){
    this.fetchData(this.last_page)
  }


  SearchReport(){
    this.fetchData(1)
  }


  searchBox(){
    this.fetchData(1)
  }




  getcallrecord(vffile, date, autocall_id){
    console.log("urls",vffile)
    console.log("date",date)
    console.log("formdate",this.fromdate)
    console.log("todate",this.todate)
    var from;
    var to;
    this.reportservice.getcallrecord(vffile,date,autocall_id, "autocall").subscribe(
        (res:any) => {
          this.AutoReportData = res["data"]
          console.log("pppppppp",this.reportForm.value.fromDate)
          if(this.fromdate == undefined){
            from = this.datePipe.transform(this.tdy, 'yyyy-MM-dd');
            to = this.datePipe.transform(this.tomorrow, 'yyyy-MM-dd');
          }else{
             from = this.fromdate
             to = this.todate
          }
          var body={"userId":this.userData.id,"userType":this.userType,"from":from,"to":to,"search":this.searchData,"callstatus":this.callstatus}
          this.usermanagementservice.load_auto_call_report_by_page(body,this.current_page).subscribe(
            (res:any) => {
              this.current_page = res.current_page;
              this.datas = res.results;
              this.last_page=res.total_pages;
              this.notifyService.sendNotification('Downloaded', 'Download Voice successfully', 'is-success')
            }
        )

        }
    )
 
 }


 CloseComment(){
  console.log("=============TRYING TO CLOSE 2====")
  this.commentopen = false
  this.call_id=""
  this.comment =""
}


  exportAsXLSX(): void {
    let text;
    let slNo= 0;
    let from = this.datePipe.transform(this.fromdate, "yyyy-MM-dd");
    let to = this.datePipe.transform(this.todate, "yyyy-MM-dd");
    let body={"userId":this.userData.id,"userType":this.userType,"from":from,"to":to,"search":this.searchData,"callstatus":this.callstatus, "download":"yes"}
    this.usermanagementservice
      .load_auto_call_report(body)
      .subscribe((res: any) => {
       
        this.ExcelPdfReportData = res["results"];
        for (let element of this.ExcelPdfReportData) {

          var objz = {
            "Sl No": slNo += 1,
            "Event ID": element["eventID"],
            "Customer": element["legbDestinationNumber"],            
            "Date & Time": this.getFormattedDateTime(
              element["callStartTime"]
            ),
            "Agent No": element["agent"],
            "Agent Name": element["AgentName"],
            "Department Name": element["queue_name"],
            "Duration": element["duration"],
            "Connected Duration": element["connected_duration"],
            "Agent Status": element["legaCallStatus"],
            "Customer Status": element["legbCallStatus"],
            'Comment':element['comment']
          };

          this.wordarray.push(objz);
        }

        text = "Autocall Report from: " + from + " To: " + to + "";

        this.excelService.exportAsExcelFile(this.wordarray, text);
        this.wordarray = [];
      });
    
  }

  downloads() {
    let slNo = 0;
    let newarray = [];
    let head = [
      [
        "Sl No",
        "Event ID",
        "Customer",
        "Date & Time",
        "Agent No",
        "Agent Name",
        "Department Name",
        "Duration",
        "Connected Duration",
        "Agent Status",
        "Customer Status  ",
        "Comment",
      ],
    ];
    let from = this.datePipe.transform(this.fromdate, "yyyy-MM-dd");
    let to = this.datePipe.transform(this.todate, "yyyy-MM-dd");
    let body={"userId":this.userData.id,"userType":this.userType,"from":from,"to":to,"search":this.searchData,"callstatus":this.callstatus, "download":"yes"}
    this.usermanagementservice
      .load_auto_call_report(body)
      .subscribe((res: any) => {
        this.ExcelPdfReportData = res["results"];
        for (let element of this.ExcelPdfReportData) {
          newarray.push([
            slNo += 1,
            element["eventID"],
            element["legbDestinationNumber"],
            this.getFormattedDateTime(element["callStartTime"]),
            element["agent"],
            element["AgentName"],
            element["queue_name"],
            element["duration"],
            element["connected_duration"],
            element["legaCallStatus"],
            element["legbCallStatus"],
            element["comment"]
          ]);
        }
        var pdf = new jsPDF();
        pdf.text("Autocall Report", 11, 8);
        pdf.setFontSize(3);
        pdf.setTextColor(100);
        (pdf as any).autoTable({
          head: head,
          body: newarray,
          theme: "grid",
          styles: { fontSize: 4 },
          tableWidth: "auto",
          columnStyles: { 0: { halign: "center", fillColor: [0, 255, 0] } }, // Cells in first column centered and green
          margin: { top: 10 },
        });
        pdf.output("dataurlnewwindow");
        pdf.save("Autocall Report" + from + "_to_" + to + ".pdf");
      });
  }


  getFormattedDateTime(datetime): string {
    const formattedDateTime = new Date(datetime).toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    const [date, time] = formattedDateTime.split(", ");
    const formattedDate = date.replace(/\//g, "-");
    return `${formattedDate}, ${time}`;
  }

}


